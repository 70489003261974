<coin-v2-dialog-frame [header]="data.title | translate">
  <div class="body-container">
    <div class="uc-dialog content">
      <p>{{ data.msg | translate }}</p>
    </div>
  </div>
  <coin-v2-dialog-footer color="white">
    <coin-action-button (click)="goBack()" svgIcon="cancel">{{ 'dialog.unsaved-changes.cancel' | translate }}</coin-action-button>
    <div class="action-buttons">
      <coin-action-button class="secondary" (click)="proceed('noSave')" svgIcon="forward">{{ 'dialog.unsaved-changes.no-save' | translate }}</coin-action-button>
      <coin-action-button class="primary" (click)="proceed('save')" [disabled]="!data.canSave" svgIcon="save">{{ 'dialog.unsaved-changes.save' | translate }}</coin-action-button>
    </div>
  </coin-v2-dialog-footer>
</coin-v2-dialog-frame>
