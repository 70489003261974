import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'coin-v2-divider',
  standalone: true,
  imports: [],
  templateUrl: './v2-divider.component.html',
  styleUrls: ['./v2-divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2DividerComponent {
  @Input({ required: true }) orientation: Orientation;
  @Input() color: ColorType = 'regular';

  @HostBinding('class')
  private get classes(): string {
    return `orientation-${this.orientation} color-${this.color}`;
  }
}

export const ORIENTATION_TYPES = ['horizontal', 'vertical'] as const;
export type Orientation = (typeof ORIENTATION_TYPES)[number];

export const COLOR_TYPES = ['dark', 'regular'] as const;
export type ColorType = (typeof COLOR_TYPES)[number];
