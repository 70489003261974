/* eslint-disable max-lines */
import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, Input, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService, DeputyService, EmulationService, GetImageWithGidService } from '@coin/modules/auth/data-access';
import { DirectsState, FirstVisitService, PermissionsService, TokenService, UserState } from '@coin/modules/auth/data-management';
import { CmsTranslationService, FeatureFlagsService, LoadingService, SetUserImageService, StorageService } from '@coin/shared/data-access';
import { ConfirmationDialogComponent } from '@coin/shared/feature-legacy-components';
import { ContentLanguage, FrontendType, PermissionResource, StorageKey } from '@coin/shared/util-enums';
import { GlobalEventsService } from '@coin/shared/util-helpers';
import { CoinUser, ConfirmationDialogConfirm, ConfirmationDialogData, Employee, EmployeeDto, EmployeeSlim, LanguageMapping, UserMenuItem } from '@coin/shared/util-models';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { SelectEmulatePersonComponent } from '../select-emulate-person/select-emulate-person.component';
import { ManageDeputiesComponent } from '../manage-deputies/manage-deputies.component';

@Component({
  selector: 'coin-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('shrinkExpand', [
      transition(':enter', [
        style({ height: '0px', minHeight: '0px', maxHeight: '0px', opacity: 0 }),
        animate('650ms ease-in-out', style({ height: '*', minHeight: '*', maxHeight: '*', opacity: 1 }))
      ]),
      transition(':leave', [animate('650ms ease-in-out', style({ height: '0px', minHeight: '0px', maxHeight: '0px', opacity: 0 }))])
    ]),
    trigger('slide', [
      transition(':enter', [style({ transform: 'translateX(-100%)' }), animate('200ms ease-in', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(-100%)', opacity: 0 }))])
    ]),
    trigger('fade', [
      transition(':enter', [style({ opacity: 0 }), animate('220ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [animate('150ms ease-in', style({ opacity: 0 }))])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent implements OnInit {
  @Input() isDeputyAllowed = true;
  @Input() isProfileAllowed = true;

  // user info
  public user = signal<CoinUser>(undefined);
  public userDetails = signal<Employee>(undefined); // used for showing org code & place of action
  public userImageSrc = signal<string>(undefined);
  public isEmulated = signal<boolean>(undefined);
  public emulationEnabled = signal<boolean>(undefined);
  public isLoggedInAsDeputy = signal<boolean>(undefined);

  public menuItems = signal<UserMenuItem[]>([]);
  public showMenu = signal(false);
  public selectableLangs = signal<LanguageMapping>({});
  public languageSelected = signal(ContentLanguage.ENGLISH);
  public remainingTimeHovered = signal(false);
  public deputies = signal<EmployeeSlim[]>([]);
  public isDeputyListVisible = signal(false);
  public languageSubmenuVisible = signal(false);
  public deputySubmenuVisible = signal(false);

  clickout = (event: MouseEvent): void => {
    if (!this.eRef.nativeElement.contains(event.target) && !(event.target as HTMLElement).closest('.deputy-menu-item')) {
      if (this.showMenu() && !this.firstVisit.status()) {
        this.openMenu();
        this.closeDeputyMenu();
      }
    }
  };

  public get isAdmin(): boolean {
    return this.permissionService.isAdmin();
  }

  public get isDeputyMenuEntryShown(): boolean {
    return this.isDeputyAllowed && (!this.isEmulated() || this.isLoggedInAsDeputy()) && this.featureFlagService.isActive('deputies');
  }

  public get isDebugModeEnabled(): boolean {
    return this.storageService.get(StorageKey.TRANSLATIONS_DEBUG_MODE_ENABLED) === 'true';
  }

  constructor(
    private eRef: ElementRef,
    private authService: AuthService,
    private translate: TranslateService,
    private firstVisit: FirstVisitService,
    private router: Router,
    private getImage: GetImageWithGidService,
    private dialog: MatDialog,
    private emulationService: EmulationService,
    private store: Store,
    private storageService: StorageService,
    private cmsTranslationService: CmsTranslationService,
    private events: GlobalEventsService,
    private setUserImageService: SetUserImageService,
    public token: TokenService,
    private userState: UserState,
    private deputyService: DeputyService,
    private loadingService: LoadingService,
    private featureFlagService: FeatureFlagsService,
    private permissionService: PermissionsService,
    private destroyRef: DestroyRef
  ) {
    this.token.init(ConfirmationDialogComponent);
  }

  async ngOnInit(): Promise<void> {
    this.getLangs();
    this.listenToLoggedInUser();
    this.token.startCheckingTimeTillLogout();
    this.isEmulated.set(!!(await this.storageService.getAsync(StorageKey.EMULATION_TOKEN)));
    this.isLoggedInAsDeputy.set((await this.authService.getEmulationDecoded())?.IsDeputy);
    this.events
      .listen('click')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => this.clickout(event));
  }

  private getLangs(): void {
    this.cmsTranslationService
      .getTranslationConfigFile(FrontendType.customer)
      .pipe(
        filter(selectableLangs => !!selectableLangs),
        tap(selectableLangs => {
          this.selectableLangs.set(selectableLangs);
        }),
        switchMap(() => this.storageService.getAsync(StorageKey.LANGUAGE)),
        tap(lang => {
          this.languageSelected.set(lang);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.translate.onLangChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(item => {
      this.storageService.setAsync(StorageKey.LANGUAGE, item.lang as ContentLanguage);
      this.languageSelected.set(item.lang as ContentLanguage);
    });
  }

  private listenToLoggedInUser(): void {
    this.store
      .select(UserState?.user)
      .pipe(
        filter(user => !!user),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(user => {
        this.user.set(user);
        this.updateEmulationEnabled();
        this.updateUserInfo();
      });
  }

  private updateEmulationEnabled(): void {
    this.emulationEnabled.set(this.user()?.roles.some(role => role?.permissions.some(({ resource }) => [PermissionResource.All, PermissionResource.Emulation].includes(resource))));
  }

  private updateUserInfo(): void {
    combineLatest([this.userState.currentUserDetails$, this.getImage.getImageWithStore(this.user().gid)])
      .pipe(first(), takeUntilDestroyed(this.destroyRef))
      .subscribe(([userDetails, image]) => {
        this.userDetails.set(userDetails);
        this.userImageSrc.set(image);
      });
  }

  public openMenu(): void {
    this.showMenu.set(!this.showMenu());
    this.languageSubmenuVisible.set(false);
    this.deputySubmenuVisible.set(false);
  }

  private enableTranslationViewer(): void {
    this.getReloadPagePermission('enable')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(hasPermission => {
        if (hasPermission) {
          this.storageService.set(StorageKey.TRANSLATIONS_DEBUG_MODE_ENABLED, 'true');
          window.location.reload();
        }
      });
  }

  private disableTranslationViewer(): void {
    this.getReloadPagePermission('disable')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(hasPermission => {
        if (hasPermission) {
          this.storageService.set(StorageKey.TRANSLATIONS_DEBUG_MODE_ENABLED, 'false');
          window.location.reload();
        }
      });
  }

  private getReloadPagePermission(action: 'enable' | 'disable'): Observable<boolean> {
    return this.dialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogConfirm>(ConfirmationDialogComponent, {
        data: {
          headline: 'Please confirm',
          msg: `Do you want to ${action} the translation viewer? Clicking "Confirm" will reload the page.`,
          confirmMsg: 'Confirm',
          cancelMsg: 'Cancel'
        }
      })
      .afterClosed()
      .pipe(map(result => !!result));
  }

  public changeLanguage(lang: ContentLanguage): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          headline: 'general.info',
          translate: true,
          msg: `general.may-need-reload-for-changes`,
          confirmMsg: 'general.btnProceed',
          cancelMsg: 'general.cancel'
        }
      })
      .afterClosed()
      .pipe(
        filter(isConfirm => !!isConfirm),
        tap(() => {
          this.translate.use(lang);
          this.openMenu();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public profileClicked(): void {
    // currently the employee profile is disabled on PROD, so just use old behaviour in that case
    this.router.navigate(['/employee-detail-overview']);
  }

  public profilePictureClicked(): void {
    this.setUserImageService.setPersonalPicture();
  }

  public languageChangeClicked(): void {
    this.languageSubmenuVisible.set(!this.languageSubmenuVisible());
  }

  public translationViewerClicked(): void {
    if (this.isDebugModeEnabled) {
      this.disableTranslationViewer();
    } else {
      this.enableTranslationViewer();
    }
  }

  public logoutClicked(): void {
    this.user.set(undefined);
    this.router.navigate(['/logout']);
  }

  public emulateClicked(): void {
    if (!this.isEmulated()) {
      this.dialog
        .open(SelectEmulatePersonComponent)
        .afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(emulateUser => {
          if (emulateUser) {
            this.getNewTokenForEmulationUser(emulateUser, 'Emulation');
          }
        });
    } else {
      this.removeEmulation();
    }
  }

  public manageDeputiesClicked(): void {
    this.deputySubmenuVisible.set(!this.deputySubmenuVisible());
  }

  get hasDirects(): boolean {
    return this.store.selectSnapshot(DirectsState.hasDirects);
  }

  public onRightClick(e: MouseEvent): void {
    e.preventDefault();
  }

  public remainingTimeHover(state: string): void {
    if (this.isEmulated()) {
      return;
    }
    this.remainingTimeHovered.set(state === 'enter');
  }

  public async renewToken(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    await this.token.renewToken();
  }

  private getNewTokenForEmulationUser(employee: EmployeeDto, authenticationType: 'Emulation' | 'Deputy'): void {
    this.emulationService
      .emulateEmployee(employee.id, authenticationType)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        setTimeout(() => {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        }, 100);
      });
  }

  public removeEmulation(): void {
    this.authService.clearEmulation();
    setTimeout(() => {
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    }, 300);
  }

  public showDeputies(): void {
    this.deputyService
      .getDeputiesForEmulation()
      .pipe(
        this.loadingService.withLoadingScreen,
        map(deputies => deputies.sort((d1, d2) => d1.firstname?.localeCompare(d2.firstname))),
        tap(data => {
          this.deputies.set(data);
        }),
        tap(() => {
          this.isDeputyListVisible.set(true);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private closeDeputyMenu(): void {
    this.deputies.set([]);
    this.isDeputyListVisible.set(false);
  }

  public emulateAsDeputy(deputy: EmployeeSlim): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          headline: 'general.info',
          translate: true,
          msg: 'deputy.emulate-msg',
          confirmMsg: 'general.btnConfirm',
          cancelMsg: 'general.btnCancel'
        }
      })
      .afterClosed()
      .pipe(
        filter(result => !!result),
        tap(() => {
          this.openMenu();
          this.closeDeputyMenu();
          this.loadingService.present(); // Will be hidden through the window reload
          this.getNewTokenForEmulationUser(deputy, 'Deputy');
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public openDeputySettings(): void {
    this.openMenu();
    this.closeDeputyMenu();
    this.dialog
      .open(ManageDeputiesComponent, { maxWidth: '820px', width: '70vw', height: '70vh', maxHeight: '800px', minHeight: '400px' })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
