@switch (operator) {
  @case ('Equal') {
    <mat-icon class="operator-icon" svgIcon="equal"></mat-icon>
  }
  @case ('NotEqual') {
    <mat-icon class="operator-icon" svgIcon="notequal"></mat-icon>
  }
  @case ('GreaterOrEqual') {
    <mat-icon class="operator-icon" svgIcon="greaterorequal"></mat-icon>
  }
  @case ('Greater') {
    <mat-icon class="operator-icon" svgIcon="greater"></mat-icon>
  }
  @case ('Less') {
    <mat-icon class="operator-icon" svgIcon="less"></mat-icon>
  }
  @case ('LessOrEqual') {
    <mat-icon class="operator-icon" svgIcon="lessorequal"></mat-icon>
  }
  @case ('StartsWith') {
    <mat-icon class="operator-icon">start</mat-icon>
  }
  @case ('EndsWith') {
    <mat-icon class="operator-icon" svgIcon="endswith"></mat-icon>
  }
  @case ('Contains') {
    <mat-icon class="operator-icon" svgIcon="contains"></mat-icon>
  }
  @case ('NotContains') {
    <mat-icon class="operator-icon" svgIcon="notcontains"></mat-icon>
  }
  @case ('Empty') {
    <mat-icon class="operator-icon">do_disturb</mat-icon>
  }
  @case ('NotEmpty') {
    <mat-icon class="operator-icon">radio_button_checked</mat-icon>
  }
  @case ('NotStartsWith') {
    <mat-icon class="operator-icon" svgIcon="notstartswith"></mat-icon>
  }
  @case ('NotEndsWith') {
    <mat-icon class="operator-icon" svgIcon="notstartswith" style="scale: -1"></mat-icon>
  }
}
