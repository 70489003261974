@if (item) {
  <div class="topic-color topic-color--{{ item.type }}" [class.task-done]="isHistoric"></div>
}
@if (item) {
  <div style="height: 100%; width: calc(100% - 1em)" [class.task-done]="isHistoric">
    @if (!isModal) {
      <div style="display: flex; height: 100%; overflow: hidden">
        <div class="date" [ngClass]="{ modal: isModal }">
          <div class="date-icon date-icon--{{ item.type }}"></div>
          <p>{{ item?.dateStart | date: dateFormat }}</p>
          @if (isTypeTask) {
            <p>- {{ item?.dateEnd | date: dateFormat }}</p>
          }
        </div>
        <img aria-label="topic item image" class="image-wrapper" [src]="topicImage" />
        @if (isLetterTask || isAllocationTask) {
          <div class="letter-task-info">
            <div class="chart-wrapper">
              <canvas #infoChart></canvas>
              <div class="percentage">
                <p>{{ getPercentage(allDoneTodos?.length / item?.todos?.length) }} %</p>
              </div>
            </div>
            <div class="done-status">
              <p>{{ 'general.completed' | translate }}</p>
              <span> <mat-icon>people</mat-icon> {{ allDoneTodos?.length }} / {{ item?.todos?.length }} </span>
            </div>
          </div>
        }
        <div class="text-info">
          <h3>{{ languageContent?.title || item?.title }}</h3>
          <div class="text" style="flex-direction: column">
            <p>{{ languageContent?.description }}</p>
          </div>
        </div>
        <div class="more-details">
          @if (isShowcase) {
            <coin-action-button class="primary">
              {{ 'season-overview.action-btn' | translate }}
            </coin-action-button>
          } @else {
            @if (
              (contentDto?.elements?.length || contentDto?.useCmsNews || !(contentDto?.useOldNews && contentDto?.elements?.length)) &&
              !isLetterTask &&
              !isAllocationTask &&
              !isFinalIncumbentTask &&
              !isHeadcountTask
            ) {
              <coin-action-button class="primary" (click)="openContent()">
                @if (item.type === communicationTypeEnum.News) {
                  {{ 'season-overview.' + item.type + '-other-btn' | translate }}
                }
                @if (item.type === communicationTypeEnum.Task) {
                  {{ 'season-overview.' + item.type + '-other-btn' | translate }}
                }
                @if (item.type === communicationTypeEnum.Announcement) {
                  {{ 'season-overview.' + item.type + '-other-btn' | translate }}
                }
              </coin-action-button>
            }
            @if (item.type === communicationTypeEnum.Task && item.season && item.simulation) {
              <coin-action-button class="primary" (click)="openBudgetDistribution()">{{ 'season-overview.' + item.type + '-bd-btn' | translate }}</coin-action-button>
            }
            @if (item.type === communicationTypeEnum.Task && isLetterTask) {
              <coin-action-button class="primary" (click)="openTafDialog()">{{ 'season-overview.' + item.type + '-other-btn' | translate }}</coin-action-button>
            }
            @if (item.type === communicationTypeEnum.Task && isAllocationTask) {
              <coin-action-button class="primary" (click)="openAllocation()">{{ 'season-overview.' + item.type + '-other-btn' | translate }}</coin-action-button>
            }
            @if (item.type === communicationTypeEnum.Task && isFinalIncumbentTask) {
              <coin-action-button class="primary" (click)="openManagerApp()">{{ 'season-overview.' + item.type + '-other-btn' | translate }}</coin-action-button>
            }
            @if (item.type === communicationTypeEnum.News && isHeadcountTask) {
              <coin-action-button class="primary" (click)="openHeadcountApproval()">{{ 'season-overview.' + item.type + '-other-btn' | translate }} </coin-action-button>
            }
            @if (item.type === communicationTypeEnum.Task && (!item.taskType || item.taskType !== todoTaskTypeEnum.ActiveConfirmation) && !item.season && !item.simulation) {
              <coin-action-button class="primary" [disabled]="true"> {{ 'season-overview.' + item.type + '-no-season-btn' | translate }}</coin-action-button>
            }
            @if (item.type === communicationTypeEnum.Task && item.taskType === todoTaskTypeEnum.ActiveConfirmation && !item.season && !item.simulation) {
              <coin-action-button class="primary" (click)="finishActiveTask()">{{ 'season-overview.' + item.type + '-finish-task-btn' | translate }}</coin-action-button>
            }
          }
        </div>
      </div>
    }

    @if (isModal) {
      <div style="display: flex; flex-direction: column; margin-left: 10px" [class.task-done]="item?.done">
        <div class="date" [ngClass]="{ modal: isModal }">
          <div class="date-icon date-icon--{{ item.type }}" style="width: 50px; height: 50px; font-size: 46px"></div>
          <div style="margin-top: -15px">
            <p>{{ item?.dateStart | date: dateFormat }} -</p>
            <p>{{ item?.dateEnd | date: dateFormat }}</p>
          </div>
        </div>

        <div class="text-info">
          <h3>{{ languageContent?.title }}</h3>
          <div class="text" style="flex-direction: column">
            <p>{{ languageContent?.description }}</p>
          </div>

          <div class="more-details"></div>
        </div>
      </div>
    }
  </div>
}
