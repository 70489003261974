import { Component, Input } from '@angular/core';
import { PermissionAction, PermissionResource, TransactionState } from '@coin/shared/util-enums';
import { TransactionStatusProcessStep } from '@coin/shared/util-models';
import { TransactionCalculationOperations } from '@coin/shared/data-access';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  // DEPRECATED: custom progress bar values
  @Input() calculating = false;
  @Input() calcProgress = 0;
  @Input() headline: string;
  @Input() isHideable: boolean;

  // RECOMMENDED: use transaction calculations operations for general progress bar
  @Input() transactionCalculationOperations: TransactionCalculationOperations;

  @Input() isIntegrated = false; // show progress bar as integrated element (e.g. tables data)

  public permissionResource = PermissionResource;
  public permissionActions = PermissionAction;

  public stepNr = (_, step: TransactionStatusProcessStep): number => step.sequenceNr;

  public get isCalculating(): boolean {
    return this.transactionCalculationOperations?.calculating ?? this.calculating;
  }

  public get calculationProgress(): number {
    return this.transactionCalculationOperations?.calcProgress ?? this.calcProgress;
  }

  public get calculationHeadline(): string {
    return this.transactionCalculationOperations?.processTitle || this.headline || 'Process running...';
  }

  public get currentProcessStepTitle(): string {
    return this.transactionCalculationOperations?.transactionStatus?.processSteps?.find(step => step.transactionState === TransactionState.Running)?.title;
  }

  public get leftTurbineStyleValue(): string {
    if (this.calculationProgress > 100) {
      return `calc(100% - 10px)`;
    }
    return `calc(${this.calculationProgress}% - 10px)`;
  }

  public cancelTransactionProcess(): void {
    this.transactionCalculationOperations.cancelTransactionProcess();
  }

  public hide(): void {
    this.transactionCalculationOperations.stopTransactionStatusCheck();
  }
}
