import { ActivityStreamItemEventType } from '../models/activity-stream-event-type.enum';

export const ACTIVITY_STREAM_SUBTITLE_UI_MAPPING = {
  [ActivityStreamItemEventType.SpecialPaymentApproval]: 'activity-stream.special-payment.proposal-state',
  [ActivityStreamItemEventType.IndividualAllocationValue]: 'activity-stream.allocation.new-compensation-changed',
  [ActivityStreamItemEventType.EmployeeSnapshotCompensationChanged]: 'activity-stream.allocation.current-compensation-changed',
  [ActivityStreamItemEventType.MeritSimulationMemberCalculated]: 'activity-stream.allocation.budget-changed',
  [ActivityStreamItemEventType.PositionRecord]: 'activity-stream.org-planning.position-record',
  [ActivityStreamItemEventType.EvaluationMemberMassUpdate]: 'activity-stream.mass-update-performed'
};
