/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityStreamsEntityChangeModel } from './activity-streams-entity-change-model';
import { EmployeesMercerStandingsUserWithRolesModel } from './employees-mercer-standings-user-with-roles-model';

/**
 * Model containing information about an activity stream.
 */
export interface ActivityStreamsActivityStreamModelOfSystemString {
  /**
   * The unique id of the activity stream.
   */
  id?: string;
  /**
   * The CorrelationId of the activity stream.
   */
  correlationId?: string;
  /**
   * The EventType of the activity stream.
   */
  eventType?: string | null;
  /**
   * The id of the user who emulated the activity stream.
   */
  emulatedById?: string | null;
  emulatedBy?: EmployeesMercerStandingsUserWithRolesModel;
  /**
   * The id of the User who created the activity stream.
   */
  createdById?: string | null;
  createdBy?: EmployeesMercerStandingsUserWithRolesModel;
  /**
   * The creation DateTime of the activity stream.
   */
  createdAt?: string;
  /**
   * The id of the User who updated the activity stream.
   */
  updatedById?: string | null;
  updatedBy?: EmployeesMercerStandingsUserWithRolesModel;
  /**
   * The update DateTime of the activity stream.
   */
  updatedAt?: string;
  /**
   * The ChangedProperties of the activity stream.
   */
  changedProperties?: Array<ActivityStreamsEntityChangeModel> | null;
  /**
   * The EventOperation of the activity stream.
   */
  eventOperation?: ActivityStreamsActivityStreamModelOfSystemStringEventOperationEnum;
  /**
   * The timestamp of the activity stream.
   */
  timestamp?: number;
}
export enum ActivityStreamsActivityStreamModelOfSystemStringEventOperationEnum {
  Created = 'Created',
  Updated = 'Updated',
  Deleted = 'Deleted'
}
