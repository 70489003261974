export enum PopulationScope {
  Employee = 'Employee',
  User = 'User',
  Position = 'Position',
  PositionSnapshot = 'PositionSnapshot',
  EclEmployee = 'EclEmployee',
  OmRule = 'OmRule',
  RestructuringEmployee = 'RestructuringEmployee',
  OrganisationManagementActivity = 'OrganisationManagementActivity'
}
