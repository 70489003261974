import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { StorageService } from '@coin/shared/data-access';
import { PermissionAction, PermissionResource, StorageKey } from '@coin/shared/util-enums';
import { Permission } from '@coin/shared/util-models';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { UserState } from './user.state';

export interface RoleGuardData {
  resource: PermissionResource;
  actions?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  private isImpersonated = false;

  constructor(
    private router: Router,
    protected store: Store,
    private ngZone: NgZone,
    private toast: ToastrService,
    private storageService: StorageService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.accessBasedOnPermissions(route);
  }

  private accessBasedOnPermissions(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const allowedResources: RoleGuardData[] = route.data?.allowedResources || [];
    const storeReq$ = !route.data?.useLoggedInUser ? this.store.select(UserState?.allPermissions) : this.store.select(UserState?.allPermissionsLoggedInUser);

    return storeReq$.pipe(
      filter(permissions => !!permissions),
      map(permissions => this.userRolesAllowed(permissions, allowedResources)),
      map(result => (typeof result === 'string' ? this.router.parseUrl(result) : result)),
      tap(result => !result && this.toast.error('Not authorized for page.') && this.ngZone.run(() => this.router.navigate([''])))
    );
  }

  private userRolesAllowed(permissions: Permission[], requiredResources: RoleGuardData[]): boolean {
    // allow resource 'All' every time

    const masterRequirement: boolean = permissions.some(permission => permission.resource === PermissionResource.All);
    const minRequirement: boolean = permissions.some(permission => permission.resource === PermissionResource.CustomerApp);

    if (masterRequirement) {
      return true;
    }

    if (!minRequirement) {
      return false;
    }

    // check allowed actions
    return requiredResources?.length
      ? requiredResources.some(role => permissions.some(permission => permission.resource === role.resource && this.actionInRequired(permission.action, role.actions)))
      : true;
  }

  private actionInRequired(action: string, requiredActions: string[]): boolean {
    return !requiredActions || requiredActions.length === 0 || action === PermissionAction.All || requiredActions.includes(action);
  }

  async setImpersonationState(): Promise<void> {
    const eli = JSON.parse(await this.storageService.getAsync(StorageKey.ELIGIBILITIES));
    if (eli) {
      this.isImpersonated = eli.impersonated;
    } else {
      this.isImpersonated = false;
    }
  }
}
