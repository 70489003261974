<coin-v2-dialog-frame [header]="'general.emulate' | translate">
  <div class="body-container">
    <div class="select-emulate-popup">
      @if (recentlyEmulated.length > 0) {
        <div class="recently-emulated-container">
          <p class="bold">{{ 'general.recently-emulated' | translate }}</p>
          <div class="recently-emulated-employees">
            @for (employee of recentlyEmulated; track employee) {
              <coin-siemens-energy-employee-preview [employee]="employee" (click)="proceed(employee)"></coin-siemens-energy-employee-preview>
            }
          </div>
        </div>
      }
      <div class="emulate-search">
        <coin-v2-text-input
          matFormFieldClass="shadow"
          (keyup.enter)="search()"
          [(ngModel)]="user.gid"
          [placeholder]="'general.gid' | translate"
          [label]="'general.user-gid' | translate"></coin-v2-text-input>
        <coin-v2-text-input
          matFormFieldClass="shadow"
          (keyup.enter)="search()"
          [(ngModel)]="user.name.lastname"
          placeholder="{{ 'general.lastname' | translate }}"
          label="{{ 'general.lastname' | translate }}"></coin-v2-text-input>
        <coin-v2-text-input
          matFormFieldClass="shadow"
          (keyup.enter)="search()"
          [(ngModel)]="user.name.firstname"
          placeholder="{{ 'general.firstname' | translate }}"
          label="{{ 'general.firstname' | translate }}"></coin-v2-text-input>
        <coin-action-button class="primary" svgIcon="create-order" (click)="search()">{{ 'general.search' | translate }} </coin-action-button>
      </div>

      <div class="emulate-list">
        @if (loading) {
          <div class="loading" @shrinkExpand>
            <mat-progress-bar class="select-emu" mode="buffer"></mat-progress-bar>
          </div>
        }

        @if (emulateablePersons.content.length === 0 && !loading) {
          <p>{{ 'general.no-emulation-users-found' | translate }}</p>
        }

        @if (!loading) {
          <div class="user-list">
            @for (userItem of emulateablePersons.content; track userItem) {
              <coin-siemens-energy-employee-preview [employee]="userItem" (click)="proceed(userItem)"></coin-siemens-energy-employee-preview>
            }
          </div>
        }
      </div>
    </div>
  </div>
  <coin-v2-dialog-footer color="white">
    <coin-action-button (click)="cancel()">{{ 'general.btnCancel' | translate }} </coin-action-button>
  </coin-v2-dialog-footer>
</coin-v2-dialog-frame>
