@if (disabled) {
  <coin-v2-dual-pill [contentLeft]="conditionLabels[currentCondition]" [themeColor]="'petrol'"></coin-v2-dual-pill>
} @else {
  <coin-v2-dual-pill
    [contentLeft]="conditionLabels[currentCondition]"
    [themeColor]="'petrol'"
    icon="sync"
    (clicked)="toggleCondition()"
    [attr.data-testid]="'rule-set-operator-toggle'"></coin-v2-dual-pill>
}
