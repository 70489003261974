<button (click)="navigateHome.emit()" aria-label="Go home" class="no-style navigation-item home"><mat-icon>home</mat-icon></button>
<div #showMoreContainer>
  @if ((hiddenItems$ | async)?.length > 0) {
    <button (click)="isShowMoreContainerVisible.set(!isShowMoreContainerVisible())" aria-label="Show more" class="no-style navigation-item">
      <mat-icon>more_horiz</mat-icon>
    </button>
  }

  @if (isShowMoreContainerVisible()) {
    <coin-v2-card padding="none">
      @for (item of hiddenItems$ | async; track trackById($index, item)) {
        <button (click)="onNavigateToItem(item.id)" [attr.aria-label]="'Navigate to ' + item.title" class="no-style show-more-navigation-item">
          <ng-container *ngTemplateOutlet="buttonContent; context: { item }"></ng-container>
        </button>
      }
    </coin-v2-card>
  }
</div>

@for (item of displayedItems$ | async; track trackById(i, item); let i = $index; let last = $last) {
  <button (click)="onNavigateToItem(item.id)" [attr.aria-label]="'Navigate to ' + item.title" [disabled]="last && !itemTemplate" class="no-style navigation-item">
    <ng-container *ngTemplateOutlet="buttonContent; context: { item }"></ng-container>
  </button>
}

<ng-template #buttonContent let-item="item">
  @if (itemTemplate) {
    <div>
      <ng-container *ngTemplateOutlet="itemTemplate; context: { item }"></ng-container>
    </div>
  } @else {
    <div>
      <div>
        <p class="bold">{{ item.title }}</p>
        @if (item.subtitle) {
          <p>{{ item.subtitle }}</p>
        }
      </div>
    </div>
  }
</ng-template>
