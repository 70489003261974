import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { StorageKey } from '@coin/shared/util-enums';
import { ProdAdmin } from '@coin/shared/util-helpers';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { StorageService } from '@coin/shared/data-access';

const ONE_SECOND_IN_MS = 1000;

@Injectable({
  providedIn: 'root'
})
export class TranslationDebugParser extends TranslateDefaultParser {
  private readonly platform = inject(PLATFORM_ID);
  private debug: boolean;
  private renderer: Renderer2;
  private ttlCache: Map<string, Date> = new Map();
  private translationComponent: 'TranslationsCustomerComponent' | 'TranslationsAdminComponent';

  constructor(
    private storageService: StorageService,
    rendererFactory: RendererFactory2
  ) {
    super();
    this.debug = this.storageService.get(StorageKey.TRANSLATIONS_DEBUG_MODE_ENABLED) === 'true';
    this.renderer = rendererFactory.createRenderer(null, null);

    if (isPlatformBrowser(this.platform)) {
      this.translationComponent = window.location.host.includes('admin') ? 'TranslationsAdminComponent' : 'TranslationsCustomerComponent';
    }
  }

  public getValue(translations: object, key: string): object | string {
    if (this.debug) {
      this.makeMatchingElementsEditable(key);
      return key;
    }

    return super.getValue(translations, key);
  }

  private makeMatchingElementsEditable(key: string): void {
    const now = new Date();
    const firstSeenAt = this.ttlCache.get(key);
    const lastSeenLongerThanOneSecondAgo = now.getTime() - (firstSeenAt?.getTime() ?? 0) > ONE_SECOND_IN_MS;
    const shouldCheckElement = lastSeenLongerThanOneSecondAgo;

    if (shouldCheckElement) {
      this.ttlCache.set(key, new Date());

      setTimeout(() => {
        const xpath = `//*[contains(text(),'${key}')]`;
        const matchingElements = document.evaluate(xpath, document, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);

        this.ttlCache.set(key, now);
        for (let i = 0; i < matchingElements.snapshotLength; i++) {
          const matchingElement = matchingElements.snapshotItem(i);

          if (!matchingElement?.parentElement.getAttribute('custom-translate-seen')) {
            const button = this.createButton();

            this.renderer.listen(button, 'click', event => {
              event.stopPropagation();
              ProdAdmin.open(this.translationComponent, { queryParams: { key } });
            });

            let textChild;
            matchingElement?.childNodes.forEach(childNode => {
              if (childNode.nodeType === Node.TEXT_NODE) {
                textChild = childNode;
              }
            });

            if (textChild) {
              try {
                this.renderer.setStyle(matchingElement, 'display', 'flex');
                this.renderer.setStyle(matchingElement, 'align-items', 'center');
                this.renderer.insertBefore(matchingElement, button, textChild);
                this.renderer.setAttribute(matchingElement.parentElement, 'custom-translate-seen', 'true');
              } catch (e) {
                // Do nothing
              }
            }
          }
        }
      }, 250);
    }
  }

  private createButton(): Node {
    const button = this.renderer.createElement('button');
    this.renderer.setStyle(button, 'margin-right', '5px');
    this.renderer.setStyle(button, 'border-radius', '12px');
    this.renderer.setStyle(button, 'padding', '2px');
    this.renderer.setStyle(button, 'border-color', 'var(--purple700)');
    this.renderer.setStyle(button, 'background-color', 'var(--purple500)');
    this.renderer.setStyle(button, 'color', 'white');
    this.renderer.setStyle(button, 'display', 'flex');
    this.renderer.setStyle(button, 'align-items', 'center');
    this.renderer.setStyle(button, 'justify-content', 'center');
    button.innerHTML = '<i class="material-icons" style="width: 14px; height: 14px; font-size: 14px;">edit</i>';

    return button;
  }
}
