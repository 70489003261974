import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'coin-v2-card',
  standalone: true,
  templateUrl: './v2-card.component.html',
  styleUrls: ['./v2-card.component.scss']
})
export class V2CardComponent implements AfterViewInit {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef<HTMLElement>
  ) {}

  @Input() padding: CardPadding = 'medium';
  @Input() rounding: CardRounding = 'small';
  @Input() shadow: CardShadow = 'medium';
  @Input() header?: string;

  @Input() set styleVariant(val: StyleVariant) {
    switch (val) {
      case 'none':
        this.rounding = 'none';
        this.shadow = 'none';
        this.padding = 'none';
        break;

      case 'large':
        this.rounding = 'extra-large';
        this.shadow = 'large';
        this.padding = 'extra-large';
        break;

      case 'medium':
      default:
        this.rounding = 'small';
        this.shadow = 'medium';
        this.padding = 'medium';
        break;
    }
  }

  ngAfterViewInit() {
    this.updateClasses();
  }

  private updateClasses(): void {
    const cardClasses = this.el.nativeElement.className.split(' ').filter(cls => ['rounding-', 'padding-', 'mat-elevation-'].find(val => cls.includes(val)));
    const desiredClasses = [
      this.cardShadows[this.shadow],
      `padding-${this.padding}${this.contentHeader && this.contentHeader.nativeElement.textContent !== '' ? '-header' : ''}`,
      `rounding-${this.rounding}`
    ].filter(cls => cls.length > 0);

    cardClasses.forEach(cls => this.renderer.removeClass(this.el.nativeElement, cls));
    desiredClasses.forEach(c => this.renderer.addClass(this.el.nativeElement, c));
  }

  @ViewChild('cardHeader') private contentHeader: ElementRef<HTMLElement>;

  private cardShadows = { none: '', medium: 'mat-elevation-z3', large: 'mat-elevation-z4' } satisfies Record<CardShadow, string>;
}

export const SIZES = ['none', 'small', 'medium', 'large', 'extra-large'] as const;
export const SHADOWS = ['none', 'medium', 'large'] as const;

export type CardPadding = (typeof SIZES)[number];
export type CardRounding = (typeof SIZES)[number];
export type CardShadow = (typeof SHADOWS)[number];
export type StyleVariant = (typeof SHADOWS)[number];
