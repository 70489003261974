<div [class.invalid]="!!errorMessage" [matTooltip]="errorMessage" class="dropdown-container lib-dropdown">
  @if (preIcon) {
    <span class="material-icons preicon">{{ preIcon }}</span>
  }
  @if (!showLoadSkeleton) {
    <mat-form-field
      class="coin-form-field-outline coin-form-field-outline--dropdown"
      [ngClass]="classAdditions"
      [appearance]="appearance"
      [floatLabel]="floatLabel"
      [class.small]="small"
      [class.tiny]="tiny"
      [class.readonly]="readonly">
      @if (label) {
        <mat-label>{{ label }}</mat-label>
      }
      <mat-select
        (selectionChange)="onSelectionChange($event)"
        (openedChange)="onOpenedChange($event)"
        (blur)="onBlur()"
        [panelClass]="long ? 'lib-dropdown-long' : 'lib-dropdown'"
        [ngModel]="value"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [multiple]="multiple"
        [compareWith]="compareWith"
        [value]="value"
        #select>
        @if (!displayFn && (!multiple || !value?.length)) {
          <mat-select-trigger>
            @if (!showSvgImages) {
              <p class="option-text" [class.need-bold]="isBold">
                {{ translateMe ? (translateMe + value | translate) : value }}
              </p>
            }

            @if (showSvgImages) {
              <div class="svg-images">
                <img [src]="'/assets/svgs/flags/' + value + '.svg'" [alt]="'Flag of ' + value" style="border-radius: 3px; border: 1px solid lightgrey" />
              </div>
            }
          </mat-select-trigger>
        }

        @if (multiple && value?.length > 1) {
          <mat-select-trigger>
            <p>
              <i
                >Selected: <b>{{ value.length }}</b></i
              >
            </p>
          </mat-select-trigger>
        }

        @if (withSearch) {
          <div class="filter-container">
            @if (loading) {
              <div class="loading">
                <app-turbine [withPole]="false" [shadow]="false"></app-turbine>
              </div>
            }
            @if (multiple && !selectAllDisabled && !showSortIndicator) {
              <coin-v2-checkbox class="check-all" (ngModelChange)="selectAll($event)" [ngModel]="allItemsSelected"> </coin-v2-checkbox>
            }
            @if (showSortIndicator) {
              <div (click)="isAscSort = !isAscSort" class="sort-direction-indicator">
                @if (isAscSort) {
                  <mat-icon>south</mat-icon>
                }
                @if (!isAscSort) {
                  <mat-icon>north</mat-icon>
                }
                <p class="sort-indicator">{{ isAscSort ? 'Asc' : 'Desc' }}</p>
              </div>
            }
            <coin-v2-text-input
              (input)="searchChanged($event.target.value)"
              [label]="'general.dropdown-search-label' | translate"
              matFormFieldClass="shadow"
              class="search-input"
              (keydown)="$event.stopPropagation()"
              #searchField />
          </div>
        }

        @if (!lazyLoadFn) {
          @if (!multiple && emptyOptionValue !== undefined) {
            <mat-option [value]="emptyOptionValue"
              ><p>{{ 'general.dropdown-no-option' | translate }}</p></mat-option
            >
          }
          @for (itm of items | pure: filteredAndSortedItems : sort : translateMe : search; track itm) {
            <mat-option [value]="bindedObjectProperty ? itm[bindedObjectProperty] : itm">
              @if (itm | pure: getText; as itemText) {
                <div class="option-content">
                  <div>
                    @if (!showSvgImages) {
                      <p class="option-text" [class.need-bold]="isBold" [matTooltip]="showOptionsAsTooltips ? (translateMe ? (translateMe + itemText | translate) : itemText) : ''">
                        {{ translateMe ? (translateMe + itemText | translate) : itemText }}
                      </p>
                    }
                    @if (showSvgImages) {
                      <div style="height: 20px; width: 30px; margin-left: auto; margin-right: auto">
                        <img [src]="'/assets/svgs/flags/' + itm + '.svg'" [alt]="'Flag of ' + itm" style="border-radius: 3px" />
                      </div>
                    }
                  </div>
                  @if (translateMe && (translateMe + itemText + '-info-tooltip' | translate) !== translateMe + itemText + '-info-tooltip') {
                    <mat-icon class="option-info-icon" [matTooltip]="translateMe ? (translateMe + itemText + '-info-tooltip' | translate) : itemText">info_outlined</mat-icon>
                  }
                </div>
              }
            </mat-option>
          }
        } @else {
          @for (itm of lazyLoadedItems$ | async; track itm) {
            <mat-option [value]="itm">
              @if (itm | pure: getText; as itemText) {
                @if (!showSvgImages) {
                  <p class="option-text" [class.need-bold]="isBold" [matTooltip]="showOptionsAsTooltips ? (translateMe ? (translateMe + itemText | translate) : itemText) : ''">
                    {{ translateMe ? (translateMe + itemText | translate) : itemText }}
                  </p>
                }
                @if (showSvgImages) {
                  <div style="height: 20px; width: 30px; margin-left: auto; margin-right: auto">
                    <img [src]="'/assets/svgs/flags/' + itm + '.svg'" [alt]="'Flag of ' + itm" style="border-radius: 3px" />
                  </div>
                }
              }
            </mat-option>
          }
        }

        @if (!lazyLoadFn ? !(items | pure: filteredAndSortedItems : sort : translateMe : search).length : !(lazyLoadedItems$ | async)?.length) {
          <mat-option [disabled]="true">- - - -</mat-option>
        }

        @if (showApplyButton) {
          <div class="button-container">
            <div class="inner-container">
              <coin-action-button class="primary" (click)="onPressApply()">{{ bottomButtonText }}</coin-action-button>
            </div>
          </div>
        }
      </mat-select>
    </mat-form-field>
  }
  @if (showLoadSkeleton) {
    <coin-action-button class="secondary skeleton" [noicon]="true" [loading]="loading"></coin-action-button>
  }
  @if (afterIcon) {
    <span class="material-icons aftericon">{{ afterIcon }}</span>
  }
  @if (canDeselect && value) {
    <mat-icon class="remove" (click)="value = null">cancel </mat-icon>
  }
</div>
