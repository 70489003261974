import { environment } from '@coin/shared/util-environments';
import { RouteBuilder, RouteSelector, RouteTree, TypedParams } from '../route-builder';
import { HomePageModule } from './home-page-module';
import { MeritSupportModule } from './merit-support-module';
import { OrgPlanningModule } from './org-planning-module';
import { MeritBudgetAllocationModule } from './merit-budget-allocation-module';
import { SpecialPaymentModule } from './special-payment-module';
import { MercerModule } from './mercer-module';
import { MercerSupportModule } from './mercer-support-module';
import { MercerStandingModule } from './mercer-standing-module';
import { OrgPlanningSupportModule } from './org-planning-support-module';
import { HeadCountApprovalModule } from './headcount-approval-module';
import { SuccessionManagementModule } from './succession-management-module';
import { SuccessionManagementSupportModule } from './succession-management-support-module';
import { EmploymentConditionLibraryModule } from './employment-condition-library-module';
import { IncentiveSupportModule } from './incentive-support-module';
import { OrgManagementModule } from './org-management-module';
import { RestructuringModule } from './restructuring-module';
import { HeadcountOverviewModule } from './headcount-overview-module';

const routes = {
  HomePageModule,
  MeritSupportModule,
  OrgPlanningModule,
  MeritBudgetAllocationModule,
  SpecialPaymentModule,
  MercerModule,
  MercerSupportModule,
  MercerStandingModule,
  OrgPlanningSupportModule,
  HeadCountApprovalModule,
  SuccessionManagementModule,
  SuccessionManagementSupportModule,
  EmploymentConditionLibraryModule,
  IncentiveSupportModule,
  OrgManagementModule,
  RestructuringModule,
  HeadcountOverviewModule
} satisfies RouteTree;
const host = environment.customerUrl;
export const Customer = new RouteBuilder(host, routes);
export type CustomerParams<T extends RouteSelector<(typeof Customer)['routes']>> = TypedParams<(typeof Customer)['routes'], T>;
