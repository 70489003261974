import { Component, DestroyRef, inject, Injector, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { HealthCheckService, LottieFilesService, StorageService } from '@coin/shared/data-access';
import { ContentLanguage, StorageKey } from '@coin/shared/util-enums';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UpdateNotificationsService } from '@coin/shared/util-helpers';

export let INJECTOR_INSTANCE: Injector;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  hideHeader = true;
  previousPath: string;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private lottieFilesService: LottieFilesService,
    private injector: Injector,
    private healthCheckService: HealthCheckService,
    private storageService: StorageService,
    private destroyRef: DestroyRef
  ) {
    inject(UpdateNotificationsService).checkForUpdates();
    this.checkBackendStatus();
    this.initializeApp();
    this.hideHeader = window.location.href.includes('/login');
    INJECTOR_INSTANCE = this.injector;
  }

  checkBackendStatus(): void {
    this.healthCheckService
      .ping()
      .pipe(
        catchError(err => {
          this.router.navigate(['fallback'], { skipLocationChange: true });
          return throwError(err);
        })
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  async ngOnInit(): Promise<void> {
    const lang = this.storageService.get(StorageKey.LANGUAGE);
    if (lang) {
      this.translate.use(lang);
    } else {
      this.translate.use(ContentLanguage.ENGLISH);
    }
    const preLoadFiles = ['add', 'edit', 'delete', 'add-white'];
    for (const itm of preLoadFiles) {
      this.lottieFilesService
        .getLottieJson(itm)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(aw => {
          this.lottieFilesService.setLottieFile(itm, aw);
        });
    }
  }

  initializeApp(): void {
    this.translate.setDefaultLang(ContentLanguage.ENGLISH);
  }
}
