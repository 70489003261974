import { EmptyGuid, RuleSetV2, RuleV2 } from '@coin/shared/util-models';
import { RuleEngineConditionTypeEnum, RuleEngineOperators } from '@coin/shared/util-enums';
import { TimestampHelper } from '@coin/shared/util-helpers';
import moment, { Moment } from 'moment';

export type ActivityStreamLoadConfig = BaseActivityStreamLoadConfig &
  (
    | ActivityStreamEvaluationMemberLoadConfig
    | ActivityStreamSpecialPaymentProposalLoadConfig
    | ActivityStreamIndividualMultiplierLoadConfig
    | ActivityStreamOrgPlanningOrganisationLoadConfig
    | ActivityStreamOrgPlanningPositionLoadConfig
    | ActivityStreamOrgPlanningEmployeeLoadConfig
    | ActivityStreamSeasonLoadConfig
    | ActivityStreamRecordLoadConfig
    | ActivityStreamSuccessionManagementTalentPoolLoadConfig
    | ActivityStreamClauseVersionLoadConfig
    | ActivityStreamTemplateVersionLoadConfig
    | ActivityStreamHeadcountApprovalPositionRequestLoadConfig
    | ActivityStreamMercerMasterDataLoadConfig
    | ActivityStreamStandingPositionEvaluationLoadConfig
    | ActivityStreamOrgManagementRuleSetLoadConfig
    | ActivityStreamOrgManagementOrgLoadConfig
  ); // add new ones here

export class BaseActivityStreamLoadConfig {
  page?: number;
  size?: number;
}

export class ActivityStreamEvaluationMemberLoadConfig extends BaseActivityStreamLoadConfig {
  seasonId: string;
  memberId: string;

  constructor(initial?: Partial<ActivityStreamEvaluationMemberLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamIndividualMultiplierLoadConfig extends BaseActivityStreamLoadConfig {
  seasonId: string;
  allocationMemberId: string;

  constructor(initial?: Partial<ActivityStreamIndividualMultiplierLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamMeritLoadConfig extends BaseActivityStreamLoadConfig {
  seasonId: string;
  allocationMemberId: string;

  constructor(initial?: Partial<ActivityStreamMeritLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamSpecialPaymentProposalLoadConfig extends BaseActivityStreamLoadConfig {
  seasonId: string;
  recordId: string;

  constructor(initial?: Partial<ActivityStreamSpecialPaymentProposalLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamOrgPlanningOrganisationLoadConfig extends BaseActivityStreamLoadConfig {
  organisationId: string;
  organisationChartId: string;

  constructor(initial?: Partial<ActivityStreamOrgPlanningOrganisationLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamOrgPlanningPositionLoadConfig extends BaseActivityStreamLoadConfig {
  organisationChartId: string;
  positionId: string;

  constructor(initial?: Partial<ActivityStreamOrgPlanningPositionLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamOrgPlanningEmployeeLoadConfig extends BaseActivityStreamLoadConfig {
  organisationChartId: string;
  employeeId: string;

  constructor(initial?: Partial<ActivityStreamOrgPlanningEmployeeLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamSeasonLoadConfig extends BaseActivityStreamLoadConfig {
  seasonId: string;
  constructor(initial?: Partial<ActivityStreamSeasonLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamRecordLoadConfig extends BaseActivityStreamLoadConfig {
  recordId: string;
  constructor(initial?: Partial<ActivityStreamRecordLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamSuccessionManagementTalentPoolLoadConfig extends BaseActivityStreamLoadConfig {
  talentPoolId: string;

  constructor(initial?: Partial<ActivityStreamRecordLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamHeadcountApprovalPositionRequestLoadConfig extends BaseActivityStreamLoadConfig {
  positionRequestId: string;
  constructor(initial?: Partial<ActivityStreamHeadcountApprovalPositionRequestLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamClauseVersionLoadConfig extends BaseActivityStreamLoadConfig {
  clauseId: string;
  versionId: string;

  constructor(initial?: Partial<ActivityStreamClauseVersionLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamTemplateVersionLoadConfig extends BaseActivityStreamLoadConfig {
  templateId: string;
  versionId: string;
  constructor(initial?: Partial<ActivityStreamTemplateVersionLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamMercerMasterDataLoadConfig extends BaseActivityStreamLoadConfig {
  employeeId: string;
  constructor(initial?: Partial<ActivityStreamMercerMasterDataLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamStandingPositionEvaluationLoadConfig extends BaseActivityStreamLoadConfig {
  employeeId: string;
  constructor(initial?: Partial<ActivityStreamStandingPositionEvaluationLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamOrgManagementRuleSetLoadConfig extends BaseActivityStreamLoadConfig {
  ruleSet: RuleSetV2;
  constructor(initial?: Partial<ActivityStreamOrgManagementRuleSetLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamOrgManagementOrgLoadConfig extends BaseActivityStreamLoadConfig {
  ruleset: RuleSetV2;

  constructor(initial?: Partial<ActivityStreamOrgManagementOrgLoadConfig>) {
    super();
    Object.assign(this, initial);
  }

  /** Convert rules to match OM Mapping */
  static convertRules(rules: RuleV2[]): RuleV2[] {
    return rules.map(rule => {
      const isRuleSet = !!rule.rules?.length;
      if (isRuleSet) return { ...rule, rules: this.convertRules(rule.rules) };

      const { id, operator, value, field } = rule;

      if (field === 'Timestamp') {
        return this.convertTimestampRule(rule);
      }

      return {
        id,
        condition: RuleEngineConditionTypeEnum.Or,
        rules: [
          {
            operator,
            field,
            listField: 'CurrentValue',
            value,
            id: EmptyGuid
          },
          {
            operator,
            field,
            listField: 'OriginalValue',
            value,
            id: EmptyGuid
          }
        ]
      };
    });
  }

  private static convertTimestampRule(rule: RuleV2): RuleV2 {
    const dateValue: Moment = moment(rule.value[0]);
    const startOfDayTimestamp = TimestampHelper.fromMoment(dateValue.startOf('day')).toString();
    const endOfDayTimestamp = TimestampHelper.fromMoment(dateValue.endOf('day')).toString();

    const lessOrEqualEndOfDay: RuleV2 = { ...rule, operator: RuleEngineOperators.LessOrEqual, value: [endOfDayTimestamp] };
    const greaterOrEqualStartOfDay: RuleV2 = { ...rule, operator: RuleEngineOperators.GreaterOrEqual, value: [startOfDayTimestamp] };
    const lessStartOfDay: RuleV2 = { ...rule, operator: RuleEngineOperators.Less, value: [startOfDayTimestamp] };
    const greaterEndOfDay: RuleV2 = { ...rule, operator: RuleEngineOperators.Greater, value: [endOfDayTimestamp] };

    switch (rule.operator) {
      case RuleEngineOperators.Equal:
        return {
          id: EmptyGuid,
          condition: RuleEngineConditionTypeEnum.And,
          rules: [greaterOrEqualStartOfDay, lessOrEqualEndOfDay]
        };
      case RuleEngineOperators.NotEqual:
        return {
          id: EmptyGuid,
          condition: RuleEngineConditionTypeEnum.Or,
          rules: [lessStartOfDay, greaterEndOfDay]
        };
      case RuleEngineOperators.LessOrEqual:
        return lessOrEqualEndOfDay;
      case RuleEngineOperators.Less:
        return lessStartOfDay;
      case RuleEngineOperators.GreaterOrEqual:
        return greaterOrEqualStartOfDay;
      case RuleEngineOperators.Greater:
        return greaterEndOfDay;
      default:
        throw new Error('Unknown rule engine operator');
    }
  }
}
