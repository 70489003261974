<div class="headline">
  <span>{{ 'general.rule-engine-headline-prefix' | translate }}</span>
  <coin-rule-engine-condition-picker [disabled]="disabled" [currentCondition]="ruleSet.condition" (changed)="setCondition($event)"></coin-rule-engine-condition-picker>
  <span>{{ 'general.rule-engine-headline-postfix' | translate }}</span>
  <div class="spacer"></div>
  @if (!disabled) {
    <mat-icon (click)="delete.emit(ruleSet)" class="delete">delete</mat-icon>
  }
</div>

@for (rule of ruleSet.rules; track rule) {
  <coin-lightweight-rule
    [disabled]="disabled"
    (changes)="changes.emit()"
    [rule]="rule"
    [deletable]="ruleSet.rules.length > 2"
    [config]="config"
    [translateKeys]="translateKeys"
    (delete)="deleteRule(rule)">
  </coin-lightweight-rule>
}
@if (!disabled) {
  <coin-v2-button (click)="addRule()" icon="add" type="secondary" [text]="'general.add-sub-rule' | translate"></coin-v2-button>
}
