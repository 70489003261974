import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { V2DualPillComponent } from '../../dual-pill/v2-dual-pill.component';
import { CONDITION_LABELS, LightWeightCondition } from '../v2-lightweight-rule-engine.types';

@Component({
  selector: 'coin-rule-engine-condition-picker',
  templateUrl: './condition-picker.component.html',
  styleUrls: ['./condition-picker.component.scss'],
  standalone: true,
  imports: [V2DualPillComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConditionPickerComponent {
  @Input({ required: true }) currentCondition: LightWeightCondition;
  @Input() disabled = false;
  @Output() changed = new EventEmitter<LightWeightCondition>();

  public readonly conditionLabels = CONDITION_LABELS;

  public toggleCondition(): void {
    this.changed.emit(this.currentCondition === 'Or' ? 'And' : 'Or');
  }
}
