import moment, { Moment } from 'moment';

export class TimestampHelper {
  private static readonly epochTicks = 621355968000000000; // for converting ticks to milliseconds
  private static readonly ticksPerMillisecond = 10000;

  public static toMoment(timestamp: number | string): Moment {
    if (!timestamp) return undefined;

    const ticksSinceEpoch = (timestamp as number) - this.epochTicks;
    const millisecondsSinceEpoch = ticksSinceEpoch / this.ticksPerMillisecond;

    return moment(millisecondsSinceEpoch);
  }

  public static fromMoment(moment: Moment): number {
    if (!moment) return undefined;

    const millisecondsSinceEpoch = moment.valueOf();
    const ticksSinceEpoch = millisecondsSinceEpoch * this.ticksPerMillisecond;

    return ticksSinceEpoch + this.epochTicks;
  }
}
