import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { DefaultControlValueAccessor } from '@coin/shared/util-helpers';
import { Subject } from 'rxjs';
import { V2ButtonComponent } from '../button/v2-button.component';
import { LightweightRuleComponent } from './lightweight-rule/lightweight-rule.component';
import { LightweightRuleSetComponent } from './lightweight-rule-set/lightweight-rule-set.component';
import {
  LightWeightCondition,
  LightWeightOperator,
  LightweightRule,
  LightweightRuleEngine,
  LightweightRuleEngineConfig,
  LightweightRuleSet
} from './v2-lightweight-rule-engine.types';
import { ConditionPickerComponent } from './condition-picker/condition-picker.component';

@Component({
  selector: 'coin-v2-rule-engine-light',
  standalone: true,
  imports: [CommonModule, MatIconModule, V2ButtonComponent, LightweightRuleComponent, LightweightRuleSetComponent, MatMenuModule, ConditionPickerComponent, TranslateModule],
  templateUrl: './v2-lightweight-rule-engine.component.html',
  styleUrls: ['./v2-lightweight-rule-engine.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => V2LightweightRuleEngineComponent),
      multi: true
    }
  ]
})
export class V2LightweightRuleEngineComponent extends DefaultControlValueAccessor<LightweightRuleEngine> {
  @Input({ required: true }) config: LightweightRuleEngineConfig;
  @Input() translateKeys = false;
  @Input() additionalFooterButtons: TemplateRef<unknown>;
  @Input() scopeSearch$: Subject<string>;

  get value(): LightweightRuleEngine {
    this._value ||= emptyRuleEngine();
    return this._value;
  }

  set value(value: LightweightRuleEngine) {
    this._value = value;
    this.onChange(value);
  }

  public isRuleSet(ruleOrRuleSet: LightweightRule | LightweightRuleSet): ruleOrRuleSet is LightweightRuleSet {
    return 'rules' in ruleOrRuleSet && !!ruleOrRuleSet.rules.length;
  }

  public addRule(): void {
    this.value = { ...this.value, rules: [...this.value.rules, emptyRule()] };
  }

  public addRuleSet(): void {
    this.value = { ...this.value, rules: [...this.value.rules, emptyRuleSet()] };
  }

  public deleteItem(ruleOrRuleSet: LightweightRule | LightweightRuleSet) {
    this.value = { ...this.value, rules: [...this.value.rules.filter(item => item !== ruleOrRuleSet)] };
  }

  public registerChanges() {
    this.value = { ...this.value };

    if (this.scopeSearch$) {
      this.scopeSearch$.next('');
    }
  }

  public setCondition(condition: LightWeightCondition) {
    this.value = { ...this.value, condition };
  }
}

export function emptyRule(): LightweightRule {
  return { field: '', operator: null, value: [] };
}

export function emptyRuleSet(): LightweightRuleSet {
  return { rules: [emptyRule(), emptyRule()], condition: 'Or' };
}

export function emptyRuleEngine(): LightweightRuleSet {
  return { condition: 'Or', rules: [] };
}

export function isValuelessOperator(operator: LightWeightOperator): boolean {
  const valuelessOperators: LightWeightOperator[] = ['NotEmpty', 'Empty'];
  return valuelessOperators.includes(operator);
}
