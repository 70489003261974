import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { EMPTY, find, fromEvent, interval, map, startWith, switchMap, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpdateNotificationsService {
  private updates = inject(SwUpdate);
  private destroyRef = inject(DestroyRef);

  public hasUpdates = toSignal(
    this.updates.versionUpdates.pipe(
      find((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'), // new version is installed
      map(() => true),
      startWith(false)
    )
  );

  public checkForUpdates(): void {
    if (!this.updates.isEnabled) return;

    fromEvent(document, 'visibilitychange')
      .pipe(
        // manually check for updates:
        // - every 10 minutes while the tab is active OR
        // - 10 seconds after re-entering an existing tab
        startWith(null),
        switchMap((_, i) => {
          if (document.hidden) return EMPTY;
          const tenMinutes = 600_000;
          const isInitialLoad = i === 0;
          return isInitialLoad ? interval(tenMinutes) : timer(10000, tenMinutes);
        }),
        switchMap(() => this.updates.checkForUpdate()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
