import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { Fn, PaginatedResult } from '@coin/shared/util-models';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, switchMap } from 'rxjs';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { SortingType } from '@coin/shared/util-enums';
import { InputComponent } from '../input/input.component';

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @deprecated Use `coin-v2-dropdown` instead.
 */
@Component({
  selector: 'coin-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ]
})
export class DropdownComponent<T = string> implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() translateMe: string = null;

  @Input() items: unknown[] = [];
  @Input() formControlName: string;

  @Input() addClass: string;
  @Input() preIcon: string;
  @Input() afterIcon: string;
  @Input() isBold = false;
  @Input() noMargin: boolean;
  @Input() shadow: boolean;
  @Input() showSvgImages = false;
  @Input() readonly = false;
  @Input() canDeselect = false;
  @Input() emptyOptionValue: unknown;
  @Input() bindedObjectProperty?: string;
  @Input() errorMessage: string;

  @Input() disabled: boolean;
  @Input() bottomButtonText: string;
  @Input() small?: boolean;
  @Input() long?: boolean;
  @Input() tiny?: boolean;
  @Input() selectAllDisabled: boolean;
  @Input() showSortIndicator: boolean;
  @Input() showApplyButton: boolean;
  @Input() multiple = false;
  @Input() singleCheckboxSelect = false;
  @Input() withSearch: boolean;
  @Input() sort = true;
  @Input() sortSelectedFirst = false;
  @Input() isAscSort = true;
  @Input() displayFn: (arg0: unknown) => string;
  @Input() compareWith: (first: unknown, second: unknown) => boolean = this.compare; // Needs to be typed any as it is generic object compare function

  @Input() searchTarget: string;
  @Input() lazyLoadFn: (page: number, search: string, key: string) => Observable<PaginatedResult<T>>;

  @Input() reloadItems$: Subject<void>;
  @Input() resetSearchInput$: Subject<void>;
  @Input() showLoadSkeleton = false;
  @Input() showOptionsAsTooltips = false;

  @Output() openedChange = new EventEmitter<boolean>();
  @Output() selectionChange = new EventEmitter<MatSelectChange>(); // TODO this can be remove and the change event can be used
  @Output() pressApplyChanges = new EventEmitter();

  @ViewChild(MatSelect, { static: false }) select: MatSelect;
  @ViewChild('searchField', { static: false }) searchField: InputComponent;
  @ViewChild(MatTooltip) tooltip: MatTooltip;

  public search = '';
  public lazyLoadedItems$: BehaviorSubject<T[]> = new BehaviorSubject([]);
  public loading = false;
  public classAdditions = {};

  private searchChanged$ = new Subject<void>();
  private unsubscribe$ = new Subject<void>();
  private page = 0;
  private hasAllFieldValues = false;
  private previousSelection: unknown[] = [];
  private wasOpened = false;

  public filteredAndSortedItems = (items: unknown[], sort: boolean, translateMe: string, search: string): unknown[] => {
    if (!items) {
      return [];
    }

    const sorted = sort ? this.sortItems(items) : items;
    return sorted.filter(item => {
      const displayText = translateMe ? this.translate.instant(translateMe + this.getText(item)) : this.getText(item);
      return displayText?.toLowerCase()?.includes(search.toLowerCase());
    });
  };

  readonly appearance = 'outline';
  readonly floatLabel = 'always';

  // eslint-disable-next-line: variable-name
  private _value: any = null;

  set value(val: any) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get value(): any {
    return this._value;
  }

  public get allItemsSelected(): boolean {
    const currentItems = (this.lazyLoadFn ? this.lazyLoadedItems$.getValue() : this.filteredAndSortedItems(this.items, this.sort, this.translateMe, this.search)) ?? [];
    const selectedItems = this.select?.options.toArray().filter(option => option.selected) ?? [];
    return currentItems?.length > 0 && selectedItems?.length === currentItems?.length;
  }

  constructor(
    private translate: TranslateService,
    private destroy: DestroyRef,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {}

  onChange: Fn = () => {};
  onTouch: Fn = () => {};

  ngOnInit(): void {
    this.classAdditions = {
      [`coin-form-field-outline--${this.addClass}`]: this.addClass,
      'coin-form-field-outline--preIcon': this.preIcon,
      'coin-form-field-outline--shadow': this.shadow,
      'no-margin': this.noMargin
    };

    this.initLazyLoading();
    this.listenToSearchInputReset();
  }

  public listenToSearchInputReset(): void {
    this.resetSearchInput$?.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.search = '';
      this.searchField.clear();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        if (changes.errorMessage?.currentValue) {
          this.tooltip?.show();
        } else {
          this.tooltip?.hide();
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initLazyLoading(): void {
    if (this.lazyLoadFn) {
      this.subscribeToSearchChanged();
      this.searchChanged$.next();
    }

    if (this.reloadItems$) {
      this.reloadItems$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.searchChanged$.next());
    }
  }

  private subscribeToSearchChanged(): void {
    this.searchChanged$
      .pipe(
        debounceTime(500),
        switchMap(() => {
          this.page = 0;
          this.loading = true;
          return this.lazyLoadFn(this.page, this.search, this.searchTarget);
        }),
        takeUntilDestroyed(this.destroy)
      )
      .subscribe(result => {
        this.loading = false;
        this.showLoadSkeleton = false;

        this.hasAllFieldValues = result?.pageCount === result?.currentPage;

        const nextItems = [...(result?.content ?? [])];

        const values: T[] = this.multiple ? (this.value ?? []) : [this.value];
        values.forEach(val => {
          if (val && !nextItems.includes(val)) nextItems.unshift(val);
        });

        this.lazyLoadedItems$.next(nextItems);
      });
  }

  private scrollEventListener = async (event: MouseEvent): Promise<void> => {
    const target = event.target as HTMLElement;
    const percentage = target.offsetHeight + target.scrollTop >= target.scrollHeight;
    const allItemsWereSelected = this.allItemsSelected;

    if (percentage && !this.hasAllFieldValues) {
      this.loading = true;
      this.cdr.markForCheck();
      // load more items
      const currentItems = this.lazyLoadedItems$.getValue();
      this.page++;

      this.lazyLoadFn(this.page, this.search, this.searchTarget)
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
          takeUntilDestroyed(this.destroy)
        )
        .subscribe(result => {
          this.hasAllFieldValues = result?.pageCount === result.currentPage;
          this.lazyLoadedItems$.next([...new Set([...currentItems, ...result.content])]);
          this.loading = false;
          setTimeout(() => {
            if (allItemsWereSelected) {
              this.selectAll(true);
            }
          });
        });
    }
  };

  private sortItems<T>(items: T[]): T[] {
    const selected = this.previousSelection;
    return [...items].sort((a, b) => {
      if (this.multiple && this.sortSelectedFirst && selected?.length) {
        const aSel = selected.includes(a);
        const bSel = selected.includes(b);
        if (aSel && !bSel) {
          return -1;
        }
        if (!aSel && bSel) {
          return 1;
        }
      }
      return this.getText(a).localeCompare(this.getText(b));
    });
  }

  public async searchChanged(value: string): Promise<void> {
    this.search = value?.trim() ?? '';
    this.searchChanged$.next();
  }

  public getText = (item: unknown): string => {
    return this.displayFn ? this.displayFn(item) : item?.toString();
  };

  public selectAll(checked: boolean): void {
    this.select.options.forEach(option => (checked ? option.select() : option.deselect()));
  }

  public open(): void {
    this.select.open();
  }

  public onOpenedChange(opened: boolean): void {
    this.openedChange.emit(opened);
    this.previousSelection = this.value;

    if (opened) {
      const panel = this.select.panel.nativeElement as HTMLElement;
      const rect = panel.getBoundingClientRect();
      if (rect.right > window.visualViewport.width) {
        panel.style.marginLeft = `-${rect.right - window.visualViewport.width + 5}px`;
      }
    }

    if (opened && this.lazyLoadFn && !this.wasOpened) {
      this.searchChanged$.next();
    }

    if (this.lazyLoadFn) {
      this.select.panel?.nativeElement?.addEventListener('scroll', this.scrollEventListener);
    }

    this.wasOpened = true;
    this.searchField?.focus();
  }

  public onSelectionChange(event: MatSelectChange): void {
    if (!this.multiple) {
      this.writeValue(event.value);
    } else if (this.singleCheckboxSelect) {
      let newValue = event.value;
      if (this.value) {
        newValue = event.value.filter(val => !this.value.includes(val));
      }
      this.writeValue(newValue);
    } else {
      // add values
      for (const value of event.value) {
        if (value !== undefined && !(this.value as string[])?.includes(value)) {
          this.writeValue(this.value ? [...this.value, value] : [value]);
        }
      }

      // remove values
      const currentItems = this.lazyLoadFn ? this.lazyLoadedItems$.getValue() : this.filteredAndSortedItems(this.items, this.sort, this.translateMe, this.search);
      const deselected = currentItems.filter(item => !(event.value as unknown[]).includes(item));
      if (deselected.length) {
        this.writeValue((this.value as string[]).filter(value => !deselected.includes(value)));
      }
    }

    this.selectionChange.emit(event);
  }

  public onPressApply(): void {
    let { value } = this;
    if (this.showSortIndicator) {
      value = { key: value[0], value: this.isAscSort ? SortingType.ASC : SortingType.DESC };
    }
    this.pressApplyChanges.emit(value);
    this.select.close();
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: Fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Fn): void {
    this.onTouch = fn;
  }

  public compare(first: unknown, second: unknown): boolean {
    return first === second;
  }

  public addItem(item: unknown): void {
    this.items = [...this.items, item];
  }

  public onBlur() {
    this.onTouch();
  }
}
