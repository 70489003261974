import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@coin/modules/auth/data-access';
import { LoadLoggedInUser, LoadUser } from '@coin/modules/auth/util';
import { PermissionResource } from '@coin/shared/util-enums';
import { Store } from '@ngxs/store';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserState } from './user.state';

@Injectable({
  providedIn: 'root'
})
export class SuccessionManagementAdminGuard {
  constructor(
    private authService: AuthService,
    private store: Store,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.authService.isAuthenticated$.value) {
      return forkJoin([this.store.dispatch(new LoadUser()), this.store.dispatch(new LoadLoggedInUser())])
        .pipe(
          map(res => {
            if (!this.hasPermissions()) {
              this.router.navigate(['unauthorized']);
              return false;
            }
            return !!res;
          })
        )
        .toPromise();
    }
    this.authService.login(state.url);
    return false;
  }

  private hasPermissions(): boolean {
    const permissions = this.store.selectSnapshot(UserState.allPermissionsLoggedInUser);
    return permissions?.some(permission => [PermissionResource.All, PermissionResource.SuccessionManagementAdmin].includes(permission.resource));
  }
}
