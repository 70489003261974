import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  signal,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { asapScheduler, timer } from 'rxjs';
import { V2TabDirective } from './tab/v2-tab.directive';

@Component({
  selector: 'coin-v2-tabs',
  templateUrl: './v2-tabs.component.html',
  styleUrls: ['./v2-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class V2TabsComponent implements AfterViewInit {
  @Input({ required: true }) type: TabStyle;
  /** Automatically selects the first tab if no other tab is selected */
  @Input() defaultToFirstTab = true;
  @Output() tabSelected = new EventEmitter<V2TabDirective>();
  @ViewChild('content') content: TemplateRef<unknown>;
  @ViewChildren('tabButton') protected tabButtons: QueryList<ElementRef<HTMLButtonElement>>;
  @ContentChildren(V2TabDirective) protected tabs: QueryList<V2TabDirective>;

  @HostBinding('class')
  private get className(): string {
    return `type-${this.type}`;
  }

  private activeTab = signal<V2TabDirective>(null);
  protected cachedTabs = new Set<V2TabDirective>();

  constructor(private destroyRef: DestroyRef) {}

  public ngAfterViewInit(): void {
    if (this.defaultToFirstTab && !this.activeTab()) {
      this.clickTab(0);
    }
  }

  public setSelectedTab(tab: V2TabDirective): void {
    this.activeTab.set(tab);

    if (tab) {
      if (tab.cache) this.cachedTabs.add(tab);
      this.tabSelected.next(tab);
    }
  }

  public isTabSelected(tab: V2TabDirective): boolean {
    if (!tab) return false;
    return tab === this.activeTab();
  }

  public selectTabByContent(content: string | TemplateRef<unknown>): void {
    const tab = this.tabs.find(t => t.content === content);
    if (tab) {
      this.setSelectedTab(tab);
    }
  }

  /** Simulate click to mark parent components for check. Required when tabs are used via @Viewchild. */
  private clickTab(index: number): void {
    timer(0, asapScheduler)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.tabButtons.get(index).nativeElement.click();
      });
  }
}

export const TAB_STYLES = ['primary', 'secondary'] as const;
export type TabStyle = (typeof TAB_STYLES)[number];
