import { HttpClient } from '@angular/common/http';
import { environment } from '@coin/shared/util-environments';
import { Type } from '@angular/core';
import { TranslateDefaultParser, TranslateLoader, TranslateModuleConfig, TranslateParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppEnvironment } from './enums/app-environments.enum';

export class CustomTranslationLoader {
  static createTranslateLoader(app: AppEnvironment) {
    return (http: HttpClient): TranslateHttpLoader => {
      return new TranslateHttpLoader(http, `${environment.cmsContentProdBucketCloudfront}translations/${app}/`, '.json');
    };
  }

  static createConfig(app: AppEnvironment, debugParser: Type<TranslateDefaultParser>): TranslateModuleConfig {
    return {
      loader: {
        provide: TranslateLoader,
        useFactory: CustomTranslationLoader.createTranslateLoader(app),
        deps: [HttpClient]
      },
      parser: { provide: TranslateParser, useClass: debugParser }
    };
  }
}
