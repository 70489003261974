<div class="comment">
  <div class="triangle"></div>

  <div class="user">
    <p class="username">
      <span
        ><b>{{ comment?.author?.lastname }}, {{ comment?.author?.firstname }} </b></span
      >
      @if (myComment || comment?.author?.gid) {
        <span class="gid">(GID: {{ comment?.author?.gid || user?.gid }})</span>
      }
    </p>
    @if (comment?.reason) {
      <p class="reason">
        <i>Reason: {{ comment?.reason }}</i>
      </p>
    }
  </div>
  <p class="text-area">{{ comment?.text }}</p>
  <div class="date">
    <span>{{ comment?.['timestamp'] || comment?.['createdAt'] | date: 'MM/dd/yyyy HH:mm' }} UTC</span>
  </div>
</div>
