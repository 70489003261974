export enum ActivityStreamItemType {
  InformationChanged = 'InformationChanged',
  OtherEvent = 'OtherEvent',

  // Mercer
  ManagerChanged = 'ManagerChanged',
  StatusChanged = 'StatusChanged',
  PlanChanged = 'PlanChanged',
  ShortlistChanged = 'ShortlistChanged',
  ApproverChanged = 'ApproverChanged',

  // Org Planning
  // Position Events
  SetAsHeadPosition = 'SetAsHeadPosition',
  RemovedAsHeadPosition = 'RemovedAsHeadPosition',
  AssignedEmployee = 'AssignedEmployee',
  UnassignedEmployee = 'UnassignedEmployee',
  CreatedPosition = 'CreatedPosition',
  DeletedPosition = 'DeletedPosition',
  MovedPosition = 'MovedPosition',
  MovedPositionBetweenOrgCharts = 'MovedPositionBetweenOrgCharts',
  UpdatedPosition = 'UpdatedPosition',

  // Organisation Events
  ChangedInformation = 'ChangedInformation',
  CreatedOrganisation = 'CreatedOrganisation',
  UpdatedOrganisation = 'UpdatedOrganisation',
  DeletedOrganisation = 'DeletedOrganisation',
  OrganisationChanged = 'OrganisationChanged',
  MovedOrganisation = 'MovedOrganisation',
  MovedOrganisationBetweenOrgCharts = 'MovedOrganisationBetweenOrgCharts',
  CreatedOrganisationRecord = 'CreatedOrganisationRecord',
  UpdatedOrganisationRecord = 'UpdatedOrganisationRecord',
  DeletedOrganisationRecord = 'DeletedOrganisationRecord',
  CreatedEmployeeRole = 'CreatedEmployeeRole',
  UpdatedEmployeeRole = 'UpdatedEmployeeRole',
  DeletedEmployeeRole = 'DeletedEmployeeRole',
  EmployeeLineManagerUpdated = 'EmployeeLineManagerUpdated',

  // Employee Events
  MovedEmployeeBetweenOrgCharts = 'MovedEmployeeBetweenOrgCharts',
  AssignedPosition = 'AssignedPosition',
  UnassignedPosition = 'UnassignedPosition',

  // Season Events
  AutoApproveAllowedChanged = 'AutoApproveAllowedChanged',
  ApprovalRequiredChanged = 'ApprovalRequiredChanged',
  PayrollStateReset = 'PayrollStateReset',
  PayrollExported = 'PayrollExported',
  SeasonCompensationOverlaid = 'SeasonCompensationOverlaid',
  SeasonBenchmarkUpdateTriggered = 'SeasonBenchmarkUpdateTriggered',
  EmployeeSnapshotDataUpdated = 'EmployeeSnapshotDataUpdated',
  MercerDataTransferred = 'MercerDataTransferred',

  // ECL Events
  FooterTranslationChanged = 'FooterTranslationChanged',
  ReviewerChanged = 'ReviewerChanged',

  // Succession Management Events
  NewRecommendationAdded = 'NewRecommendationAdded',
  NewTalentAddedToPool = 'NewTalentAddedToPool',
  StateChanged = 'StateChanged',
  TalentRemovedFromPool = 'TalentRemovedFromPool',
  RevertedRecommendation = 'RevertedRecommendation',

  // InquiryApproval
  InquiryApprovalProposal = 'InquiryApprovalProposal',
  InquiryApprovalStep = 'InquiryApprovalStep',

  // Standing Position Evaluation
  MasterDataTransfer = 'MasterDataTransfer',
  EvaluationRequest = 'EvaluationRequest',
  MasterDataJobCode = 'MasterDataJobCode',
  MasterDataPositionClass = 'MasterDataPositionClass',
  MasterDataEmployee = 'MasterDataEmployee'
}
