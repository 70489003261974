@for (tab of tabs; track tab) {
  <button #tabButton class="tab" [class.selected]="isTabSelected(tab)" (click)="setSelectedTab(tab)">
    @if (tab.icon) {
      <mat-icon>{{ tab.icon }}</mat-icon>
    }
    @if (tab.content | typeof: 'string') {
      {{ tab.content }}
    } @else {
      <ng-container *ngTemplateOutlet="tab.content"></ng-container>
    }
  </button>
}

<ng-template #content>
  @for (tab of tabs; track tab) {
    <!-- Preload all tabs that are not lazy -->
    @if (!tab.lazy || cachedTabs.has(tab) || isTabSelected(tab)) {
      <div [hidden]="!isTabSelected(tab)" class="tab-projection">
        <ng-container *ngTemplateOutlet="tab.template"></ng-container>
      </div>
    }
  }
</ng-template>
