import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'coin-icon-button',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './icon-button.component.html',
  styleUrl: './icon-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent {
  @Input() disabled = false;
  @Input({ required: true }) icon: string;
  @Input() svgIcon: string;
  @Input({ required: true }) type: IconButtonType;

  @HostBinding('class')
  private get classes(): string {
    // currently using this name for legacy support, in the future use `type-${this.type}`
    return `${this.type}`;
  }
}

export const ICON_BUTTON_TYPES = ['primary', 'secondary', 'white', 'outline'] as const;
export type IconButtonType = (typeof ICON_BUTTON_TYPES)[number];
