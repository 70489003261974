export enum LetterType {
  Other = 'Other',
  AdherenceLetter = 'AdherenceLetter', // SOG
  TargetAgreementForm = 'TargetAgreementForm', // Incentive - Records
  TargetAchievementForm = 'TargetAchievementForm', // Incentive - Achievements
  MeritAllocation = 'MeritAllocation',
  SpecialPaymentLetter = 'SpecialPaymentLetter',
  RestructuringCalculation = 'RestructuringCalculation',
  RestructuringLetter = 'RestructuringLetter'
}
