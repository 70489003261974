<coin-v2-dialog-frame [header]="header" (closeButtonClick)="cancel()">
  <ng-template #header>
    <div class="header">
      <h4 class="dialog-title">
        {{ 'deputy.settings' | translate }}
      </h4>
    </div>
  </ng-template>

  <div class="body-container">
    <div class="inner-content">
      <div class="title-container">
        <p class="title">{{ 'deputy.my-deputies' | translate }}</p>
      </div>
      <div class="deputy-list" [class.loading]="areCurrentDeputiesLoading">
        @if (currentDeputies.length === 0 && !areCurrentDeputiesLoading) {
          <p>{{ 'deputy.no-current-deputies' | translate }}</p>
        }
        @if (!areCurrentDeputiesLoading) {
          <div
            class="current-deputy-list"
            infiniteScroll
            [infiniteScrollDistance]="0.1"
            [infiniteScrollThrottle]="50"
            [scrollWindow]="false"
            (scrolled)="onScrollCurrentDeputies()">
            @for (deputy of currentDeputies; track deputy) {
              <div class="user-item">
                <coin-siemens-energy-employee-preview [employee]="deputy"></coin-siemens-energy-employee-preview>
                @if (deputy.startsAt || deputy.endsAt) {
                  <div class="timeframe">
                    <span class="start-label">{{ 'general.deputy-start' | translate }}: </span>
                    <span class="start-date">{{ (deputy.startsAt | date) ?? '*' }}</span>
                    <span class="end-label">{{ 'general.deputy-end' | translate }}: </span>
                    <span class="end-date">{{ (deputy.endsAt | date) ?? '*' }}</span>
                  </div>
                }
                <mat-icon (click)="removeDeputy(deputy)">close</mat-icon>
              </div>
            }
          </div>
        } @else {
          <div class="loading-container">
            <app-loading-screen [local]="true" [contrast]="true"></app-loading-screen>
          </div>
        }
      </div>
    </div>
    <div class="inner-content">
      <div class="title-container">
        <p class="title">{{ 'deputy.add-deputies' | translate }}</p>
        <span class="line"></span>
      </div>
      <div class="select-deputies">
        <div class="deputy-search">
          <coin-v2-text-input
            matFormFieldClass="shadow"
            (keyup.enter)="search()"
            [(ngModel)]="user.gid"
            [placeholder]="'general.gid' | translate"
            [label]="'general.user-gid' | translate"></coin-v2-text-input>
          <coin-v2-text-input
            matFormFieldClass="shadow" (keyup.enter)="search()"
            [(ngModel)]="user.name.lastname"
            [placeholder]="'general.lastname' | translate"
            [label]="'general.lastname' | translate"></coin-v2-text-input>
          <coin-v2-text-input
            matFormFieldClass="shadow"
            (keyup.enter)="search()"
            [(ngModel)]="user.name.firstname"
            [placeholder]="'general.firstname' | translate"
            [label]="'general.firstname' | translate"></coin-v2-text-input>
          <coin-action-button class="primary" svgIcon="create-order" (click)="search()">{{ 'general.search' | translate }} </coin-action-button>
        </div>

        <div class="deputy-list" [class.loading]="arePossibleDeputiesLoading">
          @if (possibleDeputyEmployees.length === 0 && !arePossibleDeputiesLoading) {
            <p>{{ 'deputy.no-possible-deputies' | translate }}</p>
          }
          @if (!arePossibleDeputiesLoading) {
            <div class="user-list" infiniteScroll [infiniteScrollDistance]="0" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScrollPossibleDeputies()">
              @for (userItem of possibleDeputyEmployees; track userItem) {
                <div @shrinkExpand (click)="addDeputy($any(userItem))" class="user-item potential-deputy">
                  <coin-siemens-energy-employee-preview [employee]="userItem"></coin-siemens-energy-employee-preview>
                </div>
              }
            </div>
          } @else {
            <div class="loading-container">
              <app-loading-screen [local]="true" [contrast]="true"></app-loading-screen>
            </div>
          }
        </div>
      </div>
    </div>
    <ng-template #loading>
      <div class="loading-container">
        <app-loading-screen [local]="true" [contrast]="true"></app-loading-screen>
      </div>
    </ng-template>
  </div>
</coin-v2-dialog-frame>
