import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { SeasonTypeEnum } from '@coin/shared/util-enums';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

interface ConfirmChecklistDialogData {
  translationKey: string;
  withFinalConfirmation?: boolean;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-confirm-checklist',
  templateUrl: './confirm-checklist.component.html',
  styleUrls: ['./confirm-checklist.component.scss']
})
export class ConfirmChecklistComponent implements OnInit {
  public checklist: string[] = [];
  checkListControl = new FormControl<boolean>(false);

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ConfirmChecklistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmChecklistDialogData,
    private translate: TranslateService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.enumerateChecklist();
  }

  private enumerateChecklist(): void {
    const checklist = [];
    for (let i = 1; i <= 30; i++) {
      const key = this.getTranslationKey(`checklist-item-${i}`);
      const item = this.translate.instant(key);
      if (item !== key) {
        checklist.push(item);
      }
    }
    this.checklist = checklist;
  }

  public getTranslationKey(item: string): string {
    return `dialog.confirm-checklist.${this.data.translationKey}.${item}`.toLowerCase();
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public accept(): void {
    let result$ = of(null);
    if (this.data.withFinalConfirmation) {
      result$ = this.dialog
        .open(ConfirmationDialogComponent, {
          disableClose: true,
          data: {
            headline: 'general.confirm',
            translate: true,
            msg: this.getTranslationKey('confirm'),
            confirmMsg: 'general.confirm',
            cancelMsg: 'general.go-back'
          }
        })
        .afterClosed()
        .pipe(filter(confirmed => !!confirmed));
    }

    result$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.dialogRef.close(true));
  }

  public static openDialog(dialog: MatDialog, translationKey: string): Observable<boolean> {
    return dialog
      .open<ConfirmChecklistComponent, ConfirmChecklistDialogData, boolean>(ConfirmChecklistComponent, {
        width: '95vw',
        maxWidth: '600px',
        maxHeight: '95vh',
        disableClose: true,
        panelClass: 'dialog-overflow-hidden',
        data: {
          translationKey
        }
      })
      .afterClosed();
  }

  public static openDialogForPublish(
    dialog: MatDialog,
    // below fields are for translation only
    actionType: 'mass-publish' | 'mass-prepublish' | 'publish' | 'prepublish',
    subject: 'letter' | 'simulation' | 'position',
    seasonType: SeasonTypeEnum,
    withFinalConfirmation = true
  ): Observable<boolean> {
    return dialog
      .open<ConfirmChecklistComponent, ConfirmChecklistDialogData, boolean>(ConfirmChecklistComponent, {
        width: '95vw',
        maxWidth: '600px',
        maxHeight: '95vh',
        disableClose: true,
        panelClass: 'dialog-overflow-hidden',
        data: {
          translationKey: `${actionType}-${subject}-${seasonType}`,
          withFinalConfirmation
        }
      })
      .afterClosed();
  }
}
