import { ActivityStreamHelper } from '../activity-stream-helper';
import { ActivityStreamEventOperation } from '../models/activity-stream-event-operation.enum';
import { ActivityStreamItemEventType } from '../models/activity-stream-event-type.enum';
import { ActivityStreamItemType } from '../models/activity-stream-item-type.enum';
import { ActivityStreamItem } from '../models/activity-stream-item.model';
import { ActivityStreamOrganisationManagementEventType } from '../models/activity-stream-organisation-mangement-event-type.enum';
import { ActivityStreamPropertyItemTypeMapping } from './activity-stream-evaluation-member-item-type.config';

export class ActivityStreamEventTypeMapping {
  public static getActivityStreamItemType(item: ActivityStreamItem): ActivityStreamItemType {
    switch (item.eventType) {
      case ActivityStreamItemEventType.JobAssignmentProposal:
        return ActivityStreamItemType.ShortlistChanged;

      case ActivityStreamItemEventType.SpecialPaymentApproval:
        return item.changedProperties?.length === 1 && item.changedProperties[0].propertyName === 'ExpectedApproverId'
          ? ActivityStreamItemType.ApproverChanged
          : ActivityStreamItemType.StatusChanged;

      case ActivityStreamItemEventType.PositionRequest:
        return ActivityStreamItemType.InquiryApprovalProposal;

      case ActivityStreamItemEventType.PositionRequestApproval:
        return ActivityStreamItemType.InquiryApprovalStep;

      case ActivityStreamItemEventType.EmployeeSnapshotManagerChanged:
        return ActivityStreamItemType.ManagerChanged;

      case ActivityStreamItemEventType.PositionRecord:
        switch (item.eventOperation) {
          case ActivityStreamEventOperation.Created:
            return ActivityStreamItemType.CreatedPosition;
          case ActivityStreamEventOperation.Deleted:
            return ActivityStreamItemType.DeletedPosition;
          case ActivityStreamEventOperation.Updated:
            if (ActivityStreamHelper.hasSomePropertyChanged(item, 'OrganisationChartId')) {
              return ActivityStreamItemType.MovedPositionBetweenOrgCharts;
            }
            if (ActivityStreamHelper.hasSomePropertyChanged(item, 'Title', 'OrgCode')) {
              return ActivityStreamItemType.MovedPosition;
            }
            if (item.changedProperties?.length === 1 && item.changedProperties?.some(property => property.propertyName === 'IsHead')) {
              if (item.changedProperties[0].currentValue === 'True') {
                return ActivityStreamItemType.SetAsHeadPosition;
              }
              return ActivityStreamItemType.RemovedAsHeadPosition;
            }
        }

        break;

      case ActivityStreamItemEventType.EmployeeRecord:
        if (ActivityStreamHelper.hasSomePropertyChanged(item, 'OrganisationChartId')) {
          return ActivityStreamItemType.MovedEmployeeBetweenOrgCharts;
        }

        if (ActivityStreamHelper.hasSomePropertyChanged(item, 'OrganisationChartId')) {
          return ActivityStreamItemType.MovedEmployeeBetweenOrgCharts;
        }

        break;

      case ActivityStreamItemEventType.JobAssignmentRecordPositionRecord:
        if (ActivityStreamHelper.hasSomePropertyChanged(item, 'EmployeeId')) {
          const employeeIdProperty = ActivityStreamHelper.getProperty(item, 'EmployeeId');
          // Current BE behaviour, when both current and original values are set it is considered an unassignment
          if (employeeIdProperty.currentValue && employeeIdProperty.originalValue) {
            return ActivityStreamItemType.UnassignedEmployee;
          }
          if (!employeeIdProperty.originalValue) {
            return ActivityStreamItemType.AssignedEmployee;
          }
        }

        break;

      case ActivityStreamItemEventType.JobAssignmentRecordEmployeeRecord:
        if (ActivityStreamHelper.hasSomePropertyChanged(item, 'EmployeeId')) {
          if (item.eventOperation === ActivityStreamEventOperation.Created) {
            return ActivityStreamItemType.AssignedPosition;
          }
          if (item.eventOperation === ActivityStreamEventOperation.Deleted) {
            return ActivityStreamItemType.UnassignedPosition;
          }
        }

        break;

      case ActivityStreamItemEventType.OrganisationRecord:
        if (item.changedProperties.every(property => !property.originalValue)) {
          return ActivityStreamItemType.CreatedOrganisation;
        }
        if (ActivityStreamHelper.hasSomePropertyChanged(item, 'OrganisationChartId')) {
          return ActivityStreamItemType.MovedOrganisationBetweenOrgCharts;
        }
        if (item.changedProperties.some(property => property.propertyName === 'ParentOrgCode')) {
          return ActivityStreamItemType.MovedOrganisation;
        }
        if (item.changedProperties.some(property => ['OrgCode', 'OrgName', 'OrgType'].includes(property.propertyName))) {
          return ActivityStreamItemType.ChangedInformation;
        }

        break;

      case ActivityStreamItemEventType.PositionOrganisationAssignmentChanged:
        return ActivityStreamItemType.OrganisationChanged;

      case ActivityStreamItemEventType.AutoApproveAllowedChanged:
      case ActivityStreamItemEventType.ApprovalRequiredChanged:
      case ActivityStreamItemEventType.PayrollStateReset:
      case ActivityStreamItemEventType.PayrollExported:
      case ActivityStreamItemEventType.SeasonCompensationOverlaid:
      case ActivityStreamItemEventType.SeasonBenchmarkUpdateTriggered:
      case ActivityStreamItemEventType.EmployeeSnapshotDataUpdated:
      case ActivityStreamItemEventType.MercerDataTransferred:
        return item.eventType as unknown as ActivityStreamItemType;

      case ActivityStreamItemEventType.Record:
        if (ActivityStreamHelper.getProperty(item, 'PayrollState')?.currentValue === 'Done') {
          return ActivityStreamItemType.PayrollExported;
        }
        if (ActivityStreamHelper.getProperty(item, 'PayrollState')?.currentValue === 'Open') {
          return ActivityStreamItemType.PayrollStateReset;
        }

        break;

      case ActivityStreamItemEventType.TalentRecommendation:
        switch (item.eventOperation) {
          case ActivityStreamEventOperation.Created:
            return ActivityStreamItemType.NewRecommendationAdded;
          case ActivityStreamEventOperation.Deleted:
            return ActivityStreamItemType.TalentRemovedFromPool;
          case ActivityStreamEventOperation.Updated:
            if (ActivityStreamHelper.hasSomePropertyChanged(item, 'TalentPoolId')) {
              return ActivityStreamItemType.InformationChanged;
            }

            if (
              this.isRevertedRecommendation(
                ActivityStreamHelper.getProperty(item, 'RecommendationState')?.originalValue as string,
                ActivityStreamHelper.getProperty(item, 'RecommendationState')?.currentValue as string
              )
            ) {
              return ActivityStreamItemType.RevertedRecommendation;
            }

            return ActivityStreamItemType.StateChanged;
        }

        break;

      case ActivityStreamItemEventType.TalentPoolAssignment:
        if (item.eventOperation === ActivityStreamEventOperation.Created) {
          if (!ActivityStreamHelper.getProperty(item, 'RecommendationState')) {
            return ActivityStreamItemType.NewTalentAddedToPool;
          }
          return ActivityStreamItemType.NewRecommendationAdded;
        }
        if (item.eventOperation === ActivityStreamEventOperation.Deleted) {
          return ActivityStreamItemType.TalentRemovedFromPool;
        }

        break;

      case ActivityStreamItemEventType.TemplateVersionFooterTranslation:
        return ActivityStreamItemType.FooterTranslationChanged;

      case ActivityStreamItemEventType.StandingPositionEvaluationRequest:
        return ActivityStreamItemType.EvaluationRequest;

      case ActivityStreamItemEventType.StandingPositionEvaluationRequestApproval:
        return ActivityStreamItemType.MasterDataTransfer;

      case ActivityStreamItemEventType.StandingPositionEvaluationMasterDataJobCode:
        return ActivityStreamItemType.MasterDataJobCode;

      case ActivityStreamItemEventType.StandingPositionEvaluationMasterDataPositionClass:
        return ActivityStreamItemType.MasterDataPositionClass;

      case ActivityStreamItemEventType.StandingPositionEvaluationMasterDataEmployee:
        return ActivityStreamItemType.MasterDataEmployee;

      case ActivityStreamItemEventType.OrganisationManagement:
        switch (ActivityStreamHelper.getProperty(item, 'EventType')?.currentValue ?? ActivityStreamHelper.getProperty(item, 'EventType')?.originalValue) {
          case ActivityStreamOrganisationManagementEventType.Position:
            switch (item.eventOperation) {
              case ActivityStreamEventOperation.Created:
                return ActivityStreamItemType.CreatedPosition;
              case ActivityStreamEventOperation.Updated:
                return ActivityStreamItemType.UpdatedPosition;
              case ActivityStreamEventOperation.Deleted:
                return ActivityStreamItemType.DeletedPosition;
            }

            break;

          case ActivityStreamOrganisationManagementEventType.OrganisationRecord:
            switch (item.eventOperation) {
              case ActivityStreamEventOperation.Created:
                return ActivityStreamItemType.CreatedOrganisationRecord;
              case ActivityStreamEventOperation.Updated:
                return ActivityStreamItemType.UpdatedOrganisationRecord;
              case ActivityStreamEventOperation.Deleted:
                return ActivityStreamItemType.DeletedOrganisationRecord;
            }

            break;

          case ActivityStreamOrganisationManagementEventType.Role:
            switch (item.eventOperation) {
              case ActivityStreamEventOperation.Created:
                return ActivityStreamItemType.CreatedEmployeeRole;
              case ActivityStreamEventOperation.Updated:
                return ActivityStreamItemType.UpdatedEmployeeRole;
              case ActivityStreamEventOperation.Deleted:
                return ActivityStreamItemType.DeletedEmployeeRole;
            }

            break;

          case ActivityStreamOrganisationManagementEventType.EmployeeLineManagerRecord:
            return ActivityStreamItemType.EmployeeLineManagerUpdated;

          default:
            switch (item.eventOperation) {
              case ActivityStreamEventOperation.Created:
                return ActivityStreamItemType.CreatedOrganisation;
              case ActivityStreamEventOperation.Updated:
                return ActivityStreamItemType.UpdatedOrganisation;
              case ActivityStreamEventOperation.Deleted:
                return ActivityStreamItemType.DeletedOrganisation;
            }
        }

        break;

      default:
        break;
    }

    if (item.changedProperties?.some(property => ['ManuallyChanged', 'NewHire', 'Promotion', 'Termination', 'PopulationChange'].includes(property.propertyName))) {
      return ActivityStreamItemType.StatusChanged;
    }

    if (item.changedProperties?.some(property => ['MeritSettingsChanged', 'EquitySettingsChanged', 'EquitySubSettingsChanged'].includes(property.propertyName))) {
      return ActivityStreamItemType.PlanChanged;
    }

    return !item.changedProperties?.length || item.changedProperties?.length > 1
      ? ActivityStreamItemType.InformationChanged
      : (ActivityStreamPropertyItemTypeMapping[item.changedProperties[0].propertyName] ?? ActivityStreamItemType.InformationChanged);
  }

  private static isRevertedRecommendation(originalValue: string, currentValue: string): boolean {
    const stateValues = ['Approved', 'Declined', 'ReviewerAccepted', 'ReviewerDeclined', 'Recommended'];
    const originalIndex = stateValues.indexOf(originalValue);
    const currentIndex = stateValues.indexOf(currentValue);
    return originalIndex < currentIndex;
  }
}
