import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  TemplateRef,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { animationFrames, BehaviorSubject, combineLatest, filter, merge, switchMap, takeWhile, tap } from 'rxjs';
import { GlobalEventsService, TinyHelpers } from '@coin/shared/util-helpers';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { BreadcrumbItem } from './breadcrumb-item.model';
import { V2CardComponent } from '../card/v2-card.component';

@Component({
  selector: 'coin-v2-breadcrumb',
  standalone: true,
  imports: [CommonModule, MatIconModule, V2CardComponent],
  templateUrl: './v2-breadcrumb.component.html',
  styleUrls: ['./v2-breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2BreadcrumbComponent implements AfterViewInit {
  @ViewChild('showMoreContainer') showMoreContainer: ElementRef;

  @Input() itemTemplate: TemplateRef<{ item: BreadcrumbItem }>;
  @Input({ required: true })
  public set items(items: BreadcrumbItem[]) {
    this.items$.next(items);
  }
  public items$ = new BehaviorSubject<BreadcrumbItem[]>([]);

  @Output() navigateHome = new EventEmitter<void>();
  @Output() navigateToItem = new EventEmitter<string>();

  private eventsService = inject(GlobalEventsService);
  private elementRef: ElementRef<HTMLElement> = inject(ElementRef);
  private destroyRef = inject(DestroyRef);

  public trackById = TinyHelpers.trackById;
  public isShowMoreContainerVisible: WritableSignal<boolean> = signal(false);

  public overflowIndex$ = new BehaviorSubject(0);
  public displayedItems$ = combineLatest([this.overflowIndex$, this.items$]).pipe(map(([overflowIndex, items]) => items.slice(overflowIndex)));
  public hiddenItems$ = combineLatest([this.overflowIndex$, this.items$]).pipe(map(([overflowIndex, items]) => items.slice(0, overflowIndex)));

  constructor() {
    this.handleOutSideClicks();
  }

  public ngAfterViewInit() {
    merge(this.items$, this.eventsService.listen('resize'))
      .pipe(
        tap(() => this.overflowIndex$.next(0)),
        // eslint-disable-next-line rxjs/no-ignored-takewhile-value
        switchMap(() => animationFrames().pipe(takeWhile(() => this.isContainerOverflowing()))),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.overflowIndex$.next(this.overflowIndex$.value + 1);
      });
  }

  public onNavigateToItem(id: string): void {
    this.isShowMoreContainerVisible.set(false);
    this.navigateToItem.emit(id);
  }

  private handleOutSideClicks(): void {
    this.eventsService
      .listen('click')
      .pipe(
        filter(event => !this.showMoreContainer.nativeElement.contains(event.target)),
        takeUntilDestroyed()
      )
      .subscribe(event => {
        this.isShowMoreContainerVisible.set(false);
      });
  }

  private isContainerOverflowing(): boolean {
    return this.elementRef.nativeElement.clientWidth < this.elementRef.nativeElement.scrollWidth;
  }
}
