import { PermissionResource } from '@coin/shared/util-enums';
import { TreeNode } from '../route-builder';

export const RestructuringModule = {
  segment: 'restructuring',
  title: 'home.restructuring',
  navigationRoot: true,
  hasExtension: true,
  children: {
    RestructuringSeasonsComponent: {
      segment: 'seasons',
      title: 'restructuring.seasons-headline',
      icon: 'toll',
      permissions: [PermissionResource.RestructuringAdmin],
      children: {
        RestructuringSeasonComponent: {
          segment: 'season',
          title: 'restructuring.seasons-headline',
          permissions: [PermissionResource.RestructuringAdmin],
          redirectToExtension: 'restructuring/season'
        }
      }
    },
    RestructuringAttributesComponent: {
      segment: 'attributes',
      title: 'restructuring.attributes-headline',
      icon: 'attribution',
      permissions: [PermissionResource.RestructuringAdmin],
      params: ['attributeType']
    },
    RestructuringReportingSettingsComponent: {
      segment: 'reporting-settings',
      title: 'restructuring.reporting-settings-headline',
      icon: 'report',
      permissions: [PermissionResource.RestructuringAdmin, PermissionResource.RestructuringAdminRestricted],
      children: {
        RestructuringReportingSettingsCountryComponent: {
          segment: 'country',
          title: 'restructuring.reporting-country-settings-headline',
          icon: 'report',
          permissions: [PermissionResource.RestructuringAdmin, PermissionResource.RestructuringAdminRestricted],
          params: ['code']
        }
      }
    },
    RestructuringCommitmentSettingsComponent: {
      segment: 'commitment-settings',
      title: 'restructuring.commitment-settings-headline',
      icon: 'commit',
      permissions: [PermissionResource.RestructuringAdmin, PermissionResource.RestructuringAdminRestricted],
      children: {
        RestructuringCommitmentSettingsCountryComponent: {
          segment: 'country',
          title: 'restructuring.commitment-country-settings-headline',
          icon: 'commit',
          permissions: [PermissionResource.RestructuringAdmin, PermissionResource.RestructuringAdminRestricted],
          params: ['code']
        }
      }
    },
    RestructuringEmployeesComponent: {
      segment: 'employees',
      title: 'restructuring.employees-headline',
      icon: 'people_alt',
      permissions: [PermissionResource.RestructuringAdmin, PermissionResource.RestructuringAdminRestricted],
      params: ['country', 'legalEntity']
    },
    RestructuringConditionLibraryCategoriesComponent: {
      segment: 'categories',
      title: 'employment-conditions-library.categories-headline',
      icon: 'category',
      permissions: [PermissionResource.RestructuringConditionLibraryAdmin],
      params: ['type', 'clusterId', 'subjectId', 'module']
    },
    RestructuringSignatureAuthorizationsComponent: {
      segment: 'signature-authorizations',
      title: 'employment-conditions-library.signature-authorizations-headline',
      icon: 'settings_applications',
      permissions: [PermissionResource.RestructuringConditionLibraryAdmin, PermissionResource.RestructuringSignatureAuthorizationAdmin]
    }
  }
} as const satisfies TreeNode;

export const RestructuringSeasonModule = {
  segment: 'restructuring/season',
  title: 'home.restructuring',
  navigationRoot: true,
  emptyPage: true,
  permissions: [PermissionResource.RestructuringAdmin],
  hideBreadcrumb: true,
  children: {
    RestructuringSeasonConfigComponent: {
      segment: 'config',
      extensionPrefix: 'season/',
      title: 'restructuring.season-config-headline',
      icon: 'settings_applications',
      permissions: [PermissionResource.RestructuringAdmin],
      params: ['seasonId'],
      paramTitle: 'seasonId'
    },
    RestructuringSeasonPopulationComponent: {
      segment: 'population',
      extensionPrefix: 'season/',
      title: 'restructuring.season-population-headline',
      icon: 'group',
      permissions: [PermissionResource.RestructuringAdmin],
      params: ['seasonId'],
      paramTitle: 'seasonId'
    },
    RestructuringSeasonParameterInputComponent: {
      segment: 'parameter-input',
      extensionPrefix: 'season/',
      title: 'restructuring.season-parameter-input-headline',
      icon: 'app_registration',
      permissions: [PermissionResource.RestructuringAdmin],
      params: ['seasonId'],
      paramTitle: 'seasonId'
    },
    RestructuringSeasonReportingSettingsComponent: {
      segment: 'reporting-settings',
      extensionPrefix: 'season/',
      title: 'restructuring.season-reporting-settings-headline',
      icon: 'report',
      permissions: [PermissionResource.RestructuringAdmin],
      params: ['seasonId'],
      paramTitle: 'seasonId'
    },
    RestructuringSeasonCommitmentSettingsComponent: {
      segment: 'commitment-settings',
      extensionPrefix: 'season/',
      title: 'restructuring.season-commitment-settings-headline',
      icon: 'commit',
      permissions: [PermissionResource.RestructuringAdmin],
      params: ['seasonId'],
      paramTitle: 'seasonId'
    },
    RestructuringSeasonPlansComponent: {
      segment: 'plans',
      extensionPrefix: 'season/',
      title: 'restructuring.season-plans-headline',
      icon: 'format_list_numbered',
      permissions: [PermissionResource.RestructuringAdmin],
      params: ['seasonId'],
      paramTitle: 'seasonId',
      children: {
        RestructuringSeasonPlansViewSetupComponent: {
          segment: 'view-setup',
          icon: 'format_list_numbered',
          title: 'restructuring.view-setup-headline',
          params: ['planId', 'sectionId'],
          paramTitle: 'planId'
        },
        RestructuringSeasonPlansMatrixComponent: {
          segment: 'matrix',
          icon: 'format_list_numbered',
          title: 'restructuring.matrix-setup-headline',
          params: ['planId', 'sectionId'],
          paramTitle: 'planId'
        }
      }
    },
    RestructuringSeasonEmployeesComponent: {
      segment: 'employees',
      extensionPrefix: 'season/',
      icon: 'supervised_user_circle',
      title: 'restructuring.season-employees-headline',
      params: ['seasonId'],
      paramTitle: 'seasonId'
    },
    RestructuringSeasonReportingComponent: {
      segment: 'reports',
      extensionPrefix: 'season/',
      title: 'restructuring.season-reporting-headline',
      icon: 'list_alt',
      permissions: [PermissionResource.RestructuringAdmin],
      params: ['seasonId'],
      paramTitle: 'seasonId'
    },
    RestructuringSeasonCommitmentsComponent: {
      segment: 'commitments',
      extensionPrefix: 'season/',
      title: 'restructuring.season-commitments-headline',
      icon: 'playlist_add_check_circle',
      permissions: [PermissionResource.RestructuringAdmin],
      params: ['seasonId'],
      paramTitle: 'seasonId'
    }
  }
} as const satisfies TreeNode;
