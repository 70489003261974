export enum RecordProcessState {
  None = 'None',
  Open = 'Open',
  TafCreated = 'TafCreated',
  TafPublished = 'TafPublished',
  ReadyForCalculation = 'ReadyForCalculation',
  Calculated = 'Calculated',
  Frozen = 'Frozen',
  AchievementLetterCreated = 'AchievementLetterCreated',
  AchievementLetterPublished = 'AchievementLetterPublished',
  ReportCreated = 'ReportCreated', // old, but sill used as fallback
  ReportPublished = 'ReportPublished' // old, but sill used as fallback
}
