<div class="color-container">
  <p class="bold">{{ isTextColorPicker ? 'Text Color:' : 'Background color:' }}</p>
  @if (!isTextColorPicker) {
    <div class="selection-container">
      <mat-radio-group [class.disabled]="disabled" [(ngModel)]="value" (change)="onChangeSelection($event)">
        @for (value of this.colors.bg; track value) {
          <mat-radio-button [value]="value.hexCode" [class]="value.class">{{ value.displayName }} </mat-radio-button>
        }
      </mat-radio-group>
    </div>
  }
  @if (isTextColorPicker) {
    <div class="selection-container">
      <mat-radio-group [class.disabled]="disabled" [(ngModel)]="value" (change)="onChangeSelection($event)">
        @for (value of this.colors.fg; track value) {
          <mat-radio-button [value]="value.hexCode" [class]="value.class">{{ value.displayName }} </mat-radio-button>
        }
      </mat-radio-group>
    </div>
  }
</div>
