import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, signal } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'coin-v2-collapse-container',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './v2-collapse-container.component.html',
  styleUrls: ['./v2-collapse-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2CollapseContainerComponent {
  @Input({ required: true }) direction: CollapseDirection;
  @Input() title: string;
  @Input()
  set isCollapsed(value: boolean) {
    this._isCollapsed.set(value);
  }
  get isCollapsed(): boolean {
    return this._isCollapsed();
  }
  private _isCollapsed = signal(false);

  @Output() isCollapsedChanged = new EventEmitter<boolean>();

  @HostBinding('class') get className(): string {
    return `
    ${this._isCollapsed() ? 'collapsed' : ''}
    ${this.direction}
    ${this.direction === 'left' || this.direction === 'right' ? 'horizontal' : 'vertical'}`;
  }

  protected toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.isCollapsedChanged.emit(this.isCollapsed);
  }

  protected get icon(): string {
    switch (this.direction) {
      case 'left':
        return 'chevron_left';
      case 'right':
        return 'chevron_right';
      case 'top':
        return 'expand_less';
      case 'bottom':
        return 'expand_more';
    }
  }
}

export const COLLAPSE_DIRECTIONS = ['left', 'right', 'top', 'bottom'] as const;
export type CollapseDirection = (typeof COLLAPSE_DIRECTIONS)[number];
