import { CommunicationWorkflowType, LetterState } from '@coin/shared/util-enums';
import { Observable } from 'rxjs';
import { BudgetAllocationLevelDateSettings } from './budget-allocation-level-date-settings.model';

export class SeasonPublicationSettings {
  id?: string;
  seasonId?: string;
  levelDateSettings: BudgetAllocationLevelDateSettings[] = [];
  dueDateAt = '';
  taskTemplateId?: string;
  mailTemplateId?: string;
  promotionRequestedMailTemplateId?: string;
  promotionNotificationMailTemplateId?: string;
  communicationDateSettings: CommunicationDateSetting[];
}

export class LevelPublicationSettings extends SeasonPublicationSettings {
  visibleLevels: number = null;
  editableLevels: number = null;
}

export class PositionPublicationSettings extends LevelPublicationSettings {}

export interface SeasonPublicationSettingsService {
  getSettings: (seasonId: string) => Observable<PositionPublicationSettings>;
  save: (body: PositionPublicationSettings, seasonId: string) => Observable<PositionPublicationSettings>;
}

export class BudgetAllocationSettings extends LevelPublicationSettings {
  publicationDatePartnerAt = '';
  publicationDateCustomerAt = '';
  managerEndDateAt = '';
  partnerEndDateAt = '';
  isManagerAppReadOnly = false;
}

export interface CommunicationDateSetting {
  id: string;
  settingsId: string;
  workflowType: CommunicationWorkflowType;
  fromLetterState: LetterState;
  toLetterState: LetterState;
  forwardAfterDays?: number;
  defaultForwardAfterDays: number;
  createdById: string;
  createdAt: string;
  updatedById: string;
  updatedAt: string;
  emulatedById: string;
}
