/* eslint-disable max-lines-per-function */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivityStreamChangedProperty,
  ActivityStreamClauseVersionLoadConfig,
  ActivityStreamEvaluationMemberLoadConfig,
  ActivityStreamEventOperation,
  ActivityStreamEventTypeMapping,
  ActivityStreamHeadcountApprovalPositionRequestLoadConfig,
  ActivityStreamIndividualMultiplierLoadConfig,
  ActivityStreamItem,
  ActivityStreamItemEventType,
  ActivityStreamItemType,
  ActivityStreamLoadConfig,
  ActivityStreamMercerMasterDataLoadConfig,
  ActivityStreamMeritLoadConfig,
  ActivityStreamOrgManagementOrgLoadConfig,
  ActivityStreamOrgPlanningEmployeeLoadConfig,
  ActivityStreamOrgPlanningOrganisationLoadConfig,
  ActivityStreamOrgPlanningPositionLoadConfig,
  ActivityStreamRecordLoadConfig,
  ActivityStreamSeasonLoadConfig,
  ActivityStreamSpecialPaymentProposalLoadConfig,
  ActivityStreamStandingPositionEvaluationLoadConfig,
  ActivityStreamSuccessionManagementTalentPoolLoadConfig,
  ActivityStreamTemplateVersionLoadConfig
} from '@coin/modules/activity-stream/util';
import { HttpHelpersService, LoadingService, OrganisationSnapshotService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { TimestampHelper } from '@coin/shared/util-helpers';
import { EmployeeSlim, PaginatedResult } from '@coin/shared/util-models';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, from, lastValueFrom, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivityStreamService {
  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private httpHelpersService: HttpHelpersService,
    private organisationSnapshotService: OrganisationSnapshotService,
    private translateService: TranslateService
  ) {}

  public getHeadcountApprovalPositionRequestActivityStreamItems(
    loadConfig: ActivityStreamHeadcountApprovalPositionRequestLoadConfig
  ): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(`/admin/api/v1/master/activity-streams/headcount-approval/position-requests/${loadConfig.positionRequestId}`, loadConfig);
  }

  public getSuccessionManagementActivityStreamItems(loadConfig: ActivityStreamSuccessionManagementTalentPoolLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(`/succession-management/partner/v1/activity-stream/talent-pools/${loadConfig.talentPoolId}`, loadConfig);
  }

  public getEvaluationMemberActivityStreamItems(loadConfig: ActivityStreamEvaluationMemberLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(
      `/admin/api/v1/master/activity-streams/seasons/position-evaluations/${loadConfig?.seasonId}/evaluation-members/${loadConfig?.memberId}`,
      loadConfig
    );
  }

  public getIndividualMultiplierActivityStreamItems(loadConfig: ActivityStreamIndividualMultiplierLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(
      `/admin/api/v1/master/activity-streams/seasons/merit/${loadConfig?.seasonId}/allocation-members/${loadConfig?.allocationMemberId}/individual-multipliers`,
      loadConfig
    );
  }

  public getMeritActivityStreamItems(loadConfig: ActivityStreamMeritLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(
      `/admin/api/v1/master/activity-streams/seasons/merit/${loadConfig?.seasonId}/allocation-members/${loadConfig?.allocationMemberId}`,
      loadConfig
    );
  }

  public getSpecialPaymentProposalActivityStreamItems(loadConfig: ActivityStreamSpecialPaymentProposalLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(`/admin/api/v1/master/activity-streams/seasons/special-payment/${loadConfig?.seasonId}/record/${loadConfig?.recordId}`, loadConfig);
  }

  public getOrgPlanningOrganisationActivityStreamItems(loadConfig: ActivityStreamOrgPlanningOrganisationLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(
      `/admin/api/v1/master/activity-streams/organisation-planning/${loadConfig?.organisationChartId}/organisation-records/${loadConfig?.organisationId}`,
      loadConfig
    );
  }

  public getOrgPlanningPositionActivityStreamItems(loadConfig: ActivityStreamOrgPlanningPositionLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(
      `/admin/api/v1/master/activity-streams/organisation-planning/${loadConfig?.organisationChartId}/position-records/${loadConfig?.positionId}`,
      loadConfig
    );
  }

  public getOrgPlanningEmployeeActivityStreamItems(loadConfig: ActivityStreamOrgPlanningEmployeeLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(
      `/admin/api/v1/master/activity-streams/organisation-planning/${loadConfig?.organisationChartId}/employee-records/${loadConfig?.employeeId}`,
      loadConfig
    );
  }

  public getRecordActivityStream(loadConfig: ActivityStreamRecordLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(`/admin/api/v1/master/activity-streams/seasons/record/${loadConfig.recordId}`, loadConfig);
  }

  public getSeasonActivityStream(loadConfig: ActivityStreamSeasonLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(`/admin/api/v1/master/activity-streams/seasons/season/${loadConfig.seasonId}`, loadConfig);
  }

  public getClauseVersionActivityStream(loadConfig: ActivityStreamClauseVersionLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(`/condition-library/customer/v1/clauses/${loadConfig.clauseId}/versions/${loadConfig.versionId}/activity-stream`, loadConfig);
  }

  public getTemplateVersionActivityStream(loadConfig: ActivityStreamTemplateVersionLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(`/condition-library/customer/v1/templates/${loadConfig.templateId}/versions/${loadConfig.versionId}/activity-stream`, loadConfig);
  }

  public getMercerMasterDataActivityStreamItems(loadConfig: ActivityStreamMercerMasterDataLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(`/mercer-standing/admin/api/v1/master/activity-streams/employee/${loadConfig.employeeId}`, loadConfig);
  }

  public getStandingPositionEvaluationActivityStreamItems(loadConfig: ActivityStreamStandingPositionEvaluationLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    return this.getActivityStreamItems(`/mercer-standing/admin/api/v1/master/activity-streams/employee/${loadConfig.employeeId}`, loadConfig);
  }

  public getOrgManagementActivityStreamItems(loadConfig: ActivityStreamOrgManagementOrgLoadConfig) {
    return this.httpClient
      .post<PaginatedResult<ActivityStreamItem>>(`${environment.api.baseUrl}/organisation-management/customer/v1/activity-stream/organisations`, {
        ruleSet: loadConfig.ruleset,
        pageable: { page: loadConfig.page, size: loadConfig.size }
      })
      .pipe(
        this.loadingService.withLoadingScreen,
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items')
      );
  }

  private getActivityStreamItems(url: string, loadConfig: ActivityStreamLoadConfig): Observable<PaginatedResult<ActivityStreamItem>> {
    const params = new HttpParams({ fromObject: { page: loadConfig.page, size: loadConfig.size } });

    return this.httpClient.get<PaginatedResult<ActivityStreamItem>>(`${environment.api.baseUrl}${url}`, { params }).pipe(
      this.loadingService.withLoadingScreen,
      mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
      this.httpHelpersService.handleError('Cannot get activity stream items')
    );
  }

  private async mapActivityStreamItems(items: PaginatedResult<ActivityStreamItem>, loadConfig: ActivityStreamLoadConfig): Promise<PaginatedResult<ActivityStreamItem>> {
    const mappedItems = (await Promise.all(items?.content?.map(item => this.mapActivityStreamItem(item, loadConfig))))?.sort((a, b) => b.timestamp - a.timestamp);
    return { ...items, content: mappedItems };
  }

  private async mapActivityStreamItem(item: ActivityStreamItem, loadConfig: ActivityStreamLoadConfig): Promise<ActivityStreamItem> {
    return {
      ...item,
      itemType: ActivityStreamEventTypeMapping.getActivityStreamItemType(item),
      changedProperties: await this.getChangedProperties(item, loadConfig),
      eventBy: this.getEventBy(item),
      eventAt: this.getEventAt(item)
    };
  }

  private async getChangedProperties(item: ActivityStreamItem, loadConfig: ActivityStreamLoadConfig): Promise<ActivityStreamChangedProperty[]> {
    // special cases
    switch (ActivityStreamEventTypeMapping.getActivityStreamItemType(item)) {
      case ActivityStreamItemType.ShortlistChanged:
        return item.changedProperties?.filter(property => property.propertyName === 'EmployeeId');
      case ActivityStreamItemType.OrganisationChanged: {
        const changedProperty = item.changedProperties?.filter(property => property.propertyName === 'OrganisationSnapshotId')?.[0];
        if (changedProperty) {
          const { seasonId } = loadConfig as ActivityStreamEvaluationMemberLoadConfig;
          return lastValueFrom(
            forkJoin(
              [changedProperty.originalValue, changedProperty.currentValue].map((organisationSnapshotId: string) =>
                this.organisationSnapshotService.getOrganisationSnapshot(seasonId, organisationSnapshotId)
              )
            ).pipe(map(([original, current]) => [{ propertyName: 'OrganisationSnapshotId', originalValue: original?.orgCode, currentValue: current?.orgCode }]))
          );
        }
      }
    }

    if (item.eventType === ActivityStreamItemEventType.PositionRequest)
      return item.changedProperties.map(changedProperty => {
        const translationKeyPrefix = 'headcount-approval.contract-type-';
        if (changedProperty.propertyName === 'ContractType') {
          return {
            propertyName: changedProperty.propertyName,
            originalValue: changedProperty.originalValue ? `${translationKeyPrefix}${String(changedProperty.originalValue).toLowerCase()}` : changedProperty.originalValue,
            currentValue: changedProperty.currentValue ? `${translationKeyPrefix}${String(changedProperty.currentValue).toLowerCase()}` : changedProperty.currentValue
          };
        }

        if (changedProperty.propertyName === 'PositionRequestState') {
          const originalStepName = changedProperty?.originalValue as string;
          const currentStepName = changedProperty?.currentValue as string;
          return {
            propertyName: changedProperty.propertyName,
            originalValue: originalStepName ? this.translateService.instant(`activity-stream.inquiry-approval-process.steps.position-request-approval.${originalStepName}`) : '-',
            currentValue: this.translateService.instant(`activity-stream.inquiry-approval-process.steps.position-request-approval.${currentStepName}`)
          };
        }

        return changedProperty;
      });

    return item.changedProperties;
  }

  private getEventBy(item: ActivityStreamItem): EmployeeSlim {
    const isSpecialEventType =
      item.eventType === ActivityStreamItemEventType.JobAssignmentRecordPositionRecord || item.eventType === ActivityStreamItemEventType.JobAssignmentRecordEmployeeRecord;
    const isCreatedOperation = item?.eventOperation === ActivityStreamEventOperation.Created;

    if (isSpecialEventType && isCreatedOperation) {
      return item?.createdBy || item?.updatedBy;
    }

    return isCreatedOperation ? item?.createdBy : item?.updatedBy;
  }

  private getEventAt(item: ActivityStreamItem): string {
    return TimestampHelper.toMoment(item.timestamp).toISOString();
  }
}
