<div class="headline">
  <span>{{ 'general.rule-engine-headline-prefix' | translate }}</span>
  <coin-rule-engine-condition-picker [disabled]="disabled" [currentCondition]="value.condition" (changed)="setCondition($event)"></coin-rule-engine-condition-picker>
  <span>{{ 'general.rule-engine-headline-postfix' | translate }}</span>
</div>

@for (ruleOrRuleSet of value.rules; track ruleOrRuleSet) {
  @if (isRuleSet(ruleOrRuleSet)) {
    <coin-lightweight-rule-set
      [ruleSet]="ruleOrRuleSet"
      [config]="config"
      [disabled]="disabled"
      [translateKeys]="translateKeys"
      (delete)="deleteItem(ruleOrRuleSet)"
      (changes)="registerChanges()"></coin-lightweight-rule-set>
  }
  @if (!isRuleSet(ruleOrRuleSet)) {
    <coin-lightweight-rule
      [rule]="ruleOrRuleSet"
      [config]="config"
      [disabled]="disabled"
      [translateKeys]="translateKeys"
      [scopeSearch$]="scopeSearch$"
      (delete)="deleteItem(ruleOrRuleSet)"
      (changes)="registerChanges()"></coin-lightweight-rule>
  }
}

<div class="buttons">
  @if (!disabled) {
    <coin-v2-button (click)="addRule()" icon="add" type="secondary" [text]="'general.rule-engine-add-rule' | translate"></coin-v2-button>
  }
  @if (!disabled) {
    <coin-v2-button (click)="addRuleSet()" icon="add" type="secondary" [text]="'general.rule-engine-add-rule-set' | translate"></coin-v2-button>
  }
  <ng-container *ngTemplateOutlet="additionalFooterButtons"></ng-container>
</div>
