import { ActivityStreamItemType } from '../models/activity-stream-item-type.enum';

export const ACTIVITY_STREAM_LIST_ITEM_UI_MAPPING: Record<ActivityStreamItemType, { backgroundColor: string; icon?: string; svgIcon?: string }> = {
  [ActivityStreamItemType.InformationChanged]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },
  [ActivityStreamItemType.ApproverChanged]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },
  [ActivityStreamItemType.ShortlistChanged]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },
  [ActivityStreamItemType.ManagerChanged]: {
    backgroundColor: 'var(--orange400)',
    icon: 'supervisor_account'
  },
  [ActivityStreamItemType.StatusChanged]: {
    backgroundColor: 'var(--petrol500)',
    icon: 'published_with_changes'
  },
  [ActivityStreamItemType.PlanChanged]: {
    backgroundColor: 'var(--petrol500)',
    icon: 'published_with_changes'
  },
  [ActivityStreamItemType.OtherEvent]: {
    backgroundColor: 'var(--purple700)',
    icon: 'event'
  },

  // Org Planning
  // Organisations
  [ActivityStreamItemType.CreatedOrganisation]: {
    backgroundColor: 'var(--green300)',
    icon: 'add'
  },
  [ActivityStreamItemType.UpdatedOrganisation]: {
    backgroundColor: 'var(--blue400)',
    icon: 'change_circle'
  },
  [ActivityStreamItemType.DeletedOrganisation]: {
    backgroundColor: 'var(--red400)',
    icon: 'deleted'
  },
  [ActivityStreamItemType.CreatedOrganisationRecord]: {
    backgroundColor: 'var(--green300)',
    icon: 'add'
  },
  [ActivityStreamItemType.UpdatedOrganisationRecord]: {
    backgroundColor: 'var(--blue400)',
    icon: 'edit'
  },
  [ActivityStreamItemType.DeletedOrganisationRecord]: {
    backgroundColor: 'var(--red400)',
    icon: 'delete'
  },
  [ActivityStreamItemType.CreatedEmployeeRole]: {
    backgroundColor: 'var(--green300)',
    icon: 'person_add_alt'
  },
  [ActivityStreamItemType.UpdatedEmployeeRole]: {
    backgroundColor: 'var(--blue400)',
    icon: 'person'
  },
  [ActivityStreamItemType.DeletedEmployeeRole]: {
    backgroundColor: 'var(--red400)',
    icon: 'delete'
  },
  [ActivityStreamItemType.ChangedInformation]: {
    backgroundColor: 'var(--blue400)',
    icon: 'edit'
  },
  [ActivityStreamItemType.OrganisationChanged]: {
    backgroundColor: 'var(--blue400)',
    icon: 'change_circle'
  },
  [ActivityStreamItemType.MovedOrganisation]: {
    backgroundColor: 'var(--purple700)',
    icon: 'trending_up'
  },
  [ActivityStreamItemType.MovedOrganisationBetweenOrgCharts]: {
    backgroundColor: 'var(--orange400)',
    icon: 'trending_up'
  },
  [ActivityStreamItemType.EmployeeLineManagerUpdated]: {
    backgroundColor: 'var(--orange400)',
    icon: 'supervisor_account'
  },

  // Positions
  [ActivityStreamItemType.CreatedPosition]: {
    backgroundColor: 'var(--green300)',
    icon: 'work'
  },
  [ActivityStreamItemType.UpdatedPosition]: {
    backgroundColor: 'var(--blue400)',
    icon: 'work'
  },
  [ActivityStreamItemType.DeletedPosition]: {
    backgroundColor: 'var(--red400)',
    icon: 'delete'
  },
  [ActivityStreamItemType.MovedPosition]: {
    backgroundColor: 'var(--purple700)',
    icon: 'work'
  },
  [ActivityStreamItemType.SetAsHeadPosition]: {
    backgroundColor: 'var(--green400)',
    svgIcon: 'org_head_white'
  },
  [ActivityStreamItemType.RemovedAsHeadPosition]: {
    backgroundColor: 'var(--red300)',
    svgIcon: 'remove_org_head_white'
  },
  [ActivityStreamItemType.AssignedEmployee]: {
    backgroundColor: 'var(--petrol500)',
    icon: 'person_add'
  },
  [ActivityStreamItemType.UnassignedEmployee]: {
    backgroundColor: 'var(--orange400)',
    svgIcon: 'manager_white'
  },
  [ActivityStreamItemType.MovedPositionBetweenOrgCharts]: {
    backgroundColor: 'var(--blue500)',
    icon: 'trending_up'
  },

  // Employee
  [ActivityStreamItemType.MovedEmployeeBetweenOrgCharts]: {
    backgroundColor: 'var(--orange400)',
    icon: 'trending_up'
  },
  [ActivityStreamItemType.AssignedPosition]: {
    backgroundColor: 'var(--petrol500)',
    icon: 'person_add'
  },
  [ActivityStreamItemType.UnassignedPosition]: {
    backgroundColor: 'var(--orange400)',
    svgIcon: 'manager_white'
  },

  // Season
  [ActivityStreamItemType.AutoApproveAllowedChanged]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },
  [ActivityStreamItemType.ApprovalRequiredChanged]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },
  [ActivityStreamItemType.PayrollStateReset]: {
    backgroundColor: 'var(--orange400)',
    icon: 'restart_alt'
  },
  [ActivityStreamItemType.PayrollExported]: {
    backgroundColor: 'var(--petrol500)',
    icon: 'file_download'
  },
  [ActivityStreamItemType.SeasonCompensationOverlaid]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },
  [ActivityStreamItemType.SeasonBenchmarkUpdateTriggered]: {
    backgroundColor: 'var(--blue400)',
    icon: 'restart_alt'
  },
  [ActivityStreamItemType.EmployeeSnapshotDataUpdated]: {
    backgroundColor: 'var(--blue400)',
    icon: 'restart_alt'
  },
  [ActivityStreamItemType.MercerDataTransferred]: {
    backgroundColor: 'var(--blue400)',
    icon: 'trending_up'
  },

  // Succession Management
  [ActivityStreamItemType.NewRecommendationAdded]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },
  [ActivityStreamItemType.NewTalentAddedToPool]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },
  [ActivityStreamItemType.StateChanged]: {
    backgroundColor: 'var(--green300)',
    icon: 'published_with_changes'
  },
  [ActivityStreamItemType.TalentRemovedFromPool]: {
    backgroundColor: 'var(--red300)',
    icon: 'delete'
  },
  [ActivityStreamItemType.RevertedRecommendation]: {
    backgroundColor: 'var(--green300)',
    icon: 'published_with_changes'
  },

  // ECL
  [ActivityStreamItemType.FooterTranslationChanged]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },
  [ActivityStreamItemType.ReviewerChanged]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },

  // InquiryApproval
  [ActivityStreamItemType.InquiryApprovalStep]: {
    backgroundColor: 'var(--purple700)',
    icon: 'event'
  },
  [ActivityStreamItemType.InquiryApprovalProposal]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },

  // Standing Position Evaluation
  [ActivityStreamItemType.MasterDataTransfer]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },
  [ActivityStreamItemType.MasterDataJobCode]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },
  [ActivityStreamItemType.MasterDataPositionClass]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },
  [ActivityStreamItemType.MasterDataEmployee]: {
    backgroundColor: 'var(--blue400)',
    icon: 'info'
  },
  [ActivityStreamItemType.EvaluationRequest]: {
    backgroundColor: 'var(--blue400)',
    icon: 'person_add'
  }
};
