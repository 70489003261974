import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { PermissionResource } from '@coin/shared/util-enums';
import { Permission } from '@coin/shared/util-models';
import { UserState } from './user.state';

@Injectable({
  providedIn: 'root'
})
export class ChildRouteRedirectorGuard {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const permissions = this.store.selectSnapshot(UserState?.allPermissions);
    const firstChild = route.routeConfig.children.find(
      child =>
        (!child.redirectTo && !child.data.allowedResources) ||
        permissions.some(p => p.resource === PermissionResource.All) ||
        ((child.data?.allowedResources ?? []) as Permission[]).map(({ resource }) => resource).some(r => permissions.some(p => p.resource === r))
    );

    if (!firstChild) {
      return false;
    }

    // TODO: For now this only works at the first nesting-level (e.g. /user-mgmt/users)
    if (state.url.split('/').filter(s => !!s).length > 1) {
      return true;
    }

    return this.router.parseUrl(`${state.url}/${firstChild.path}`);
  }
}
