/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SharedRuleEngineRuleSetModel } from './shared-rule-engine-rule-set-model';

export interface OrganisationManagementRulesRuleModel {
  id?: string;
  type?: string | null;
  title?: string | null;
  ruleSetId?: string | null;
  severity?: OrganisationManagementRulesRuleModelSeverityEnum;
  validation?: OrganisationManagementRulesRuleModelValidationEnum;
  message?: string | null;
  /**
   * Check Value (for example OrgName)
   */
  configuration?: string | null;
  isActive?: boolean;
  ruleSet?: SharedRuleEngineRuleSetModel;
  validFrom?: string;
  validTo?: string | null;
  createdAt?: string | null;
  createdById?: string | null;
}
export enum OrganisationManagementRulesRuleModelSeverityEnum {
  Error = 'Error',
  Warning = 'Warning',
  Information = 'Information'
}
export enum OrganisationManagementRulesRuleModelValidationEnum {
  OrgNameMatchOrgCodeAbbreviation = 'OrgNameMatchOrgCodeAbbreviation',
  ProhibitedOrgCodeAbbreviation = 'ProhibitedOrgCodeAbbreviation',
  ProhibitedOrgName = 'ProhibitedOrgName',
  EmptyHeadPosition = 'EmptyHeadPosition',
  OnlyOneHeadPosition = 'OnlyOneHeadPosition',
  MaximumOrgLevel = 'MaximumOrgLevel',
  InheritancePrinciple = 'InheritancePrinciple',
  InvalidContractStatus = 'InvalidContractStatus',
  InvalidWorkforceType = 'InvalidWorkforceType',
  OrgCodeAbbreviationMatchOrgName = 'OrgCodeAbbreviationMatchOrgName',
  ProhibitedUseOfSubRegion = 'ProhibitedUseOfSubRegion',
  NumberAsOrgCodeOnlyOnLowestLevel = 'NumberAsOrgCodeOnlyOnLowestLevel'
}
