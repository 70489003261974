<div #overlayOrigin class="overlay-origin">
  <coin-v2-text-input
    [loading]="loading"
    [formControl]="searchControl"
    [inputDebounce]="300"
    (blur)="onTouch(value)"
    [placeholder]="placeholder"
    [label]="label"
    (customClear)="clearValue()"
    [clearable]="!isRequired && !!value && !disabled"
    [icon]="icon"
    [class.has-value]="!!value"
    #searchInputElement></coin-v2-text-input>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayOpen]="this.isSearchResultVisible() && !loading && !!searchControl.value"
  [cdkConnectedOverlayWidth]="overlayOrigin.clientWidth"
  [cdkConnectedOverlayDisableClose]="true"
  (detach)="handleDetach()">
  <div class="search-results" aria-label="search-box-results">
    @if (results?.length > 0) {
      <div class="scroll-container">
        @for (result of results; track result) {
          <div (click)="selectResult(result.value)" class="result">
            <ng-container *ngTemplateOutlet="result.template"></ng-container>
          </div>
        }
      </div>
    } @else {
      <div class="no-results-container">
        <p>{{ noResultsTextKey | translate }}</p>
      </div>
    }
  </div>
</ng-template>
