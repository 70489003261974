import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Employee, EmployeeSlim, PaginatedResult } from '@coin/shared/util-models';
import moment from 'moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'coin-siemens-energy-employee-preview',
  templateUrl: './employee-preview.component.html',
  styleUrls: ['./employee-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeePreviewComponent {
  @Input() thirdLineTemplate?: TemplateRef<unknown>;
  @Input() employee: Employee;
  @Input() boldName = true;
  @Input() light = false;
  @Input() big = false;
  @Input() small = false;
  @Input() tiny = false;
  @Input() noWrap? = false;
  @Input() wrap? = false;
  @Input() singleLine = true;
  @Input() showGid = false;
  @Input() showOrgCode = true;
  @Input() showFlag = true;
  @Input() positionClass?: number;
  @Input() smallerPositionClass = false;
  @Input() subTitle?: string;
  @Input() actionButtonTitle?: string;
  @Input() showActionButton = false;
  @Input() actionIcon = 'person_add';
  @Input() showHintIfNoPositionClassSet = false;
  @Input() placeholderText: string;
  @Input() imageIcon: string;
  @Input() idKey = 'GID';
  @Input() isOrgCodeEditable = false;
  @Input() namePrefix: string;
  @Input() customTopInfo: string[];
  @Input() customSubInfo: string[];
  @Input() showExitDateWarning = false;
  @Input() lazyLoadOrgCodesFn: (page: number, search: string) => Observable<PaginatedResult<string>>;
  @Input() hideText = false;
  @Output() orgCodeChanged = new EventEmitter<string>();
  @Output() action = new EventEmitter<void>();

  public country(employee: Employee): string {
    return employee?.countryCode ?? employee?.placeOfAction ?? employee?.country;
  }

  public get roleNames(): string[] {
    return (this.employee as EmployeeSlim)?.roleNames || [];
  }

  public get showPlaceholder(): boolean {
    return !this.employee && !!this.placeholderText;
  }

  public get formattedCompanyExitDate(): string {
    if (!this.employee?.companyExitDate) return '';

    return moment(this.employee.companyExitDate).format('MM/DD/YYYY');
  }

  public onActionClick(): void {
    this.action.emit();
  }
}
